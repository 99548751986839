import React, { useState } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';
import { SocketMessage } from '@app/types/types';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
interface props {
  recipient: string;
}

const NewMessage: React.FC<props> = (props) => {
  const userCTX = useUser();
  const wsCTX = useWs();
  const [messageToSend, setMessageToSend] = useState('');
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'white' }}>
      <InputText
        style={{
          backgroundColor: '#f0f0f0',
          borderRadius: '15px',
          height: '24px',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        value={messageToSend}
        onChange={(e) => setMessageToSend(e.target.value)}
      ></InputText>
      <Button
        icon="fa-solid fa-paper-plane fa-xs"
        style={{ height: '24px', width: '24px' }}
        onClick={() => {
          const newMessage: SocketMessage = {
            SenderUserId: userCTX.authenticationInfos.user?.Id ?? '',
            RecipientUserId: props.recipient,
            Message: messageToSend,
            MessageType: 2,
            Date: moment(Date.now()).format('YYYY-MM-DD'),
          };
          //  wsCTX.updateMessagesSended(newMessage);
          wsCTX.Send({
            Message: newMessage,
            RecipientUserId: userCTX.authenticationInfos.user?.Id as string,
            MessageType: 2,
            RecipientHotelId: userCTX.authenticationInfos.selectedHotel?.IdHotel.toString() as string,
            SenderUserId: props.recipient,
            Date: moment(Date.now()).format('YYYY-MM-DD'),
          });
          wsCTX.Send({
            Message: newMessage,
            RecipientUserId: props.recipient,
            MessageType: 2,
            RecipientHotelId: userCTX.authenticationInfos.selectedHotel?.IdHotel.toString() as string,
            SenderUserId: userCTX.authenticationInfos.user?.Id as string,
            Date: moment(Date.now()).format('YYYY-MM-DD'),
          });

          setMessageToSend('');
        }}
      ></Button>
    </div>
  );
};

export default NewMessage;
