import CustomDay from '@app/components/Common/planning/CustomDay';
import CustomResourceBreakingType from '@app/components/Common/planning/CustomResourceBreakingType';
import Reservation from '@app/components/Common/planning/Reservation';
import EnvoiStock from '@app/components/Common/Stock/MajStock';
import FermetureStock from '@app/components/Common/Stock/FermetureStock';
import RenvoiStock from '@app/components/Common/Stock/RenvoiStock';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useEstock } from '@app/modules/main/estock/EstockContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { Room } from '@app/types/Hotel/room';
import { EStock, Host, PlanningLine } from '@app/types/types';
import { getDeltaInDays } from '@app/utils/dates';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import {
  Eventcalendar,
  MbscCalendarDayData,
  MbscCalendarEvent,
  MbscCalendarEventData,
  localeAr,
  localeDe,
  localeEn,
  localeEs,
  localeFr,
  localeZh,
} from '@mobiscroll/react';
import { Tooltip } from '@mui/material';
import { current } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useCallback, useEffect, useState } from 'react';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import EstockFilters from '@app/components/Common/filter/EstockFilters';
import colors from '@app/styles/colors';

const CustomTimelineRow = styled.div`
  .mbsc-timeline-row {
    height: 60px;
  }
  .mbsc-timeline-column,
  .mbsc-timeline-header-column {
    background-color: #333;
  }
`;
interface GroupedEvent {
  [date: string]: {
    [type: string]: PlanningLine;
  };
}
const EStockPage = () => {
  const dataFactoryCTX = useDataFactory();
  const filtersCTX = useFilters();
  const userCTX = useUser();
  const planningCTX = usePlanning();
  const rooms = dataFactoryCTX?.DataDictionnary?.Rooms;
  const [resources, setResources] = useState<Room[]>([]);
  const zoneEvents = planningCTX.ZoneEvents;
  const [t] = useTranslation();
  const arrayOfTotal: PlanningLine[] = [];
  const [event, setEvent] = useState<PlanningLine[]>([]);
  const [groupedEvenet, setGroupedEvent] = useState<GroupedEvent>();
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const [partners, setPartners] = useState<Host[] | undefined>([]);
  const [currentPartner, setCurrentPartner] = useState<Host | null>();

  const PlanningDate = userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate;
  const subFormCTX = useSubForm();
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  type RoomAccumulator = { [key: string]: Room[] };
  const estockCTX = useEstock();

  useEffect(() => {
    if (filtersCTX.filters.estockFilters.DateStart && filtersCTX.filters.estockFilters.DateEnd) {
      estockCTX
        .getEstockData(
          filtersCTX.filters.estockFilters.DateStart as string,
          filtersCTX.filters.estockFilters.DateEnd as string,
        )
        .then((result) => {
          if (result) {
            const estockdata: EStock = result?.Data as EStock;

            setPartners(
              estockdata.Partners.map((e) => {
                return { name: e.Name, id: e.IdHost };
              }),
            );
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.estockFilters.DateEnd, filtersCTX.filters.estockFilters.DateStart, rooms]);

  useEffect(() => {
    if (partners) {
      setCurrentPartner(partners[0]);
    }
  }, [partners]);

  useEffect(() => {
    if (partners && currentPartner !== null && currentPartner !== undefined && estockCTX.EStock) {
      // Récupérer les ressources et ajouter l'élément 'TOTAL'
      let resources = estockCTX.EStock?.Partners.find(
        (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
      )?.RoomTypes.map((r, index) => ({
        id: 'p' + (index + 1).toString(),
        Id: r.Id,
        key: (index + 1).toString(),
        RoomNumber: r.Libelle,
        RoomType: r.Libelle,
        RoomTypeDescription: r.Description,
        PositionRoom: 0,
        PositionRoomType: 0,
        icon: '\u2615\uFE0F',
        eventCreation: false,
        isCommunicating: r.IsCommunicating,
      }));

      if (resources) {
        // Créer l'élément TOTAL
        const totalElement = {
          id: 'TOTAL',
          Id: 'TOTAL',
          key: 'TOTAL',
          RoomNumber: 'TOTAL',
          RoomType: 'TOTAL',
          PositionRoom: 0,
          RoomTypeDescription: 'TOTAL',
          PositionRoomType: 0,
          icon: '\u2615\uFE0F',
          eventCreation: false,
          isCommunicating: false,
        };

        // Ajouter TOTAL dans le tableau initial
        resources.push(totalElement);

        // Séparer les chambres communicantes et non-communicantes
        const communicatingRooms = resources.filter((room) => room.isCommunicating);
        const nonCommunicatingRooms = resources.filter((room) => !room.isCommunicating && room.Id !== 'TOTAL');

        // Fusionner les chambres non-communicantes, l'élément TOTAL, puis les chambres communicantes
        const finalResources = [...nonCommunicatingRooms, totalElement, ...communicatingRooms];

        // Mettre à jour les ressources avec l'ordre ajusté
        setResources(finalResources);
      }
    }
  }, [currentPartner, estockCTX, partners]);

  useEffect(() => {
    if (partners !== undefined && currentPartner !== undefined && currentPartner !== null && estockCTX.EStock) {
      for (
        let i = 0;
        i <=
        getDeltaInDays(
          filtersCTX.filters.estockFilters.DateStart as number,
          filtersCTX.filters.estockFilters.DateEnd as number,
        );
        i++
      ) {
        resources.forEach((item) => {
          if (item.id !== 'TOTAL') {
            const distribuee = estockCTX.EStock?.Partners.find(
              (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
            )
              ?.RoomTypes.find((r) => r.Id === item.Id)
              ?.Data.find(
                (t) =>
                  moment(t.Date).format('YYYY-MM-DD') ===
                  moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                    .add(i, 'day')
                    .format('YYYY-MM-DD'),
              )?.Quantite as number;

            const alotement = estockCTX.EStock?.Partners.find(
              (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
            )
              ?.RoomTypes.find((r) => r.Id === item.Id)
              ?.Data.find(
                (t) =>
                  moment(t.Date).format('YYYY-MM-DD') ===
                  moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                    .add(i, 'day')
                    .format('YYYY-MM-DD'),
              )?.Allotment as number;
            const dispo = estockCTX.EStock?.Partners.find(
              (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
            )
              ?.RoomTypes.find((r) => r.Id === item.Id)
              ?.Data.find(
                (t) =>
                  moment(t.Date).format('YYYY-MM-DD') ===
                  moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                    .add(i, 'day')
                    .format('YYYY-MM-DD'),
              )?.Available as number;

            const dayTotal = {
              start: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYYMMDD'),
              end: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYYMMDD'),
              resource: item.id,
              stockType: true,
              editable: false,
              HostList: partners,
              RoomTypeList: resources.map((e) => {
                return { name: e.RoomNumber, id: e.Id };
              }),
              IdHost: currentPartner,
              IdRoomType: item.Id,
              DateStart: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYY-MM-DD'),
              DateEnd: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYY-MM-DD'),
              id:
                item.id +
                moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                  .add(i, 'day')
                  .format('YYYYMMDD'),
              Id:
                item.id +
                moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                  .add(i, 'day')
                  .format('YYYYMMDD'),
              IdRoom: item.id.toString(),
              isCommunicating: item.isCommunicating,
              Distribuee: distribuee?.toString(),
              Disponible: dispo?.toString(),
              Alotement: alotement?.toString(),
              DisplayName: distribuee?.toString(),
              Type: item.RoomNumber,
              foreground:
                (dispo ?? 0) > distribuee
                  ? '#B7FFBB'
                  : dispo === distribuee
                    ? '#D5E6FD'
                    : dispo < distribuee
                      ? '#FFB1A9'
                      : 'disabled',
              isTextBold: dispo > distribuee ? false : dispo === distribuee ? false : dispo < distribuee ? true : '',
            };
            arrayOfTotal.push(dayTotal);
          }
        });
      }
      if (partners !== undefined && currentPartner !== undefined && estockCTX.EStock) {
        for (
          let i = 0;
          i <=
          getDeltaInDays(
            filtersCTX.filters.estockFilters.DateStart as number,
            filtersCTX.filters.estockFilters.DateEnd as number,
          );
          i++
        ) {
          const distribuee = estockCTX.EStock?.Partners.find(
            (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
          )?.Total.find(
            (t) =>
              moment(t.Date).format('YYYY-MM-DD') ===
              moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYY-MM-DD'),
          )?.Quantite as number;

          const alotement = estockCTX.EStock?.Partners.find(
            (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
          )?.Total.find(
            (t) =>
              moment(t.Date).format('YYYY-MM-DD') ===
              moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYY-MM-DD'),
          )?.Allotment as number;
          const dispo = estockCTX.EStock?.Partners.find(
            (e) => e.Name === partners.find((t) => t.id === e.IdHost)?.name,
          )?.Total.find(
            (t) =>
              moment(t.Date).format('YYYY-MM-DD') ===
              moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYY-MM-DD'),
          )?.Available as number;

          const dayTotal = {
            start: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
              .add(i, 'day')
              .format('YYYYMMDD'),
            end: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
              .add(i, 'day')
              .format('YYYYMMDD'),
            resource: 'TOTAL',
            stockType: true,
            editable: false,
            HostList: partners,
            isCommunicating: true,

            IdHost: currentPartner,
            RoomTypeList: resources.map((e) => {
              return { name: e.RoomNumber, id: e.Id };
            }),
            DateStart: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
              .add(i, 'day')
              .format('YYYY-MM-DD'),
            DateEnd: moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
              .add(i, 'day')
              .format('YYYY-MM-DD'),
            id:
              'total' +
              moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYYMMDD'),
            Id:
              'total' +
              moment(new Date(filtersCTX.filters.estockFilters.DateStart as Date))
                .add(i, 'day')
                .format('YYYYMMDD'),
            IdRoom: 'TOTAL',
            Distribuee: distribuee?.toString(),
            Disponible: dispo?.toString(),
            Alotement: alotement?.toString(),
            DisplayName: distribuee?.toString(),
            Type: 'TOTAL',
            foreground:
              (dispo ?? 0) > distribuee
                ? '#B7FFBB'
                : dispo === distribuee
                  ? '#D5E6FD'
                  : dispo < distribuee
                    ? '#FFB1A9'
                    : 'disabled',
            isTextBold: dispo > distribuee ? false : dispo === distribuee ? false : dispo < distribuee ? true : '',
          };
          arrayOfTotal.push(dayTotal);
        }
      }
      setEvent(arrayOfTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resources,
    partners,
    currentPartner,
    estockCTX.EStock,
    filtersCTX.filters.estockFilters.DateStart,
    filtersCTX.filters.estockFilters.DateEnd,
  ]);

  useEffect(() => {
    const groupedByDate = event.reduce((acc: any, curr) => {
      const { DateStart, Type } = curr;

      // Si la date n'existe pas encore dans l'accumulateur, on l'initialise avec un objet vide
      if (!acc[DateStart]) {
        acc[DateStart] = {};
      }

      // Au lieu d'un tableau, on associe directement l'objet au type
      acc[DateStart][Type] = curr;

      return acc;
    }, {});
    setGroupedEvent(groupedByDate);
  }, [event]);
  useEffect(() => {
    if (filtersCTX.filters.estockFilters.SelectedHotels) {
      planningCTX.getZoneEvents({
        ...filtersCTX.filters.planningFilters,
        SelectedHotels: filtersCTX.filters.estockFilters.SelectedHotels,
        DateEnd: filtersCTX.filters.estockFilters.DateEnd as Date,
        DateStart: filtersCTX.filters.estockFilters.DateStart as Date,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersCTX.filters.estockFilters.DateEnd,
    filtersCTX.filters.estockFilters.DateStart,
    filtersCTX.filters.estockFilters.SelectedHotels,
  ]);
  const getDateFormat = (date: Date | number | string) => {
    const tempdate = new Date(date);

    const userLocale = userCTX.authenticationInfos?.language; // Use the user's browser language as a default

    // Define options for date formatting
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

    // Format the date based on the user's locale
    const formattedDate = tempdate.toLocaleDateString(userLocale, options);
    return formattedDate;
  };
  const renderDay = useCallback(
    (args: MbscCalendarDayData) => {
      // console.log('renderday');

      return <CustomDay caller="estock" args={args} date={args.date} zoneEvents={zoneEvents}></CustomDay>;
    },
    [zoneEvents],
  );
  const scheduleEvent = useCallback(
    (data: MbscCalendarEventData | null, mobileData?: PlanningLine | null) => {
      return (
        <Reservation
          data={data ? data : undefined}
          original={mobileData ? mobileData : undefined}
          PlanningDate={PlanningDate as string}
        />
      );
    },
    [PlanningDate],
  );
  const renderResourceHeader = useCallback(() => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2px',
        }}
      >
        <Button
          style={{
            width: '100%',
            height: '30px',
            marginLeft: '10px',
            marginRight: '10px',
            backgroundColor: '#f0f0f0',
            color: colors.colorPrimaryMain,
          }}
          onClick={() => {
            const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
            const newSubForm = {
              id: id, // ou utilisez un ID unique
              component: EnvoiStock, // Remplacez par le composant que vous souhaitez afficher
              data: {
                RoomTypeList: resources.map((e) => {
                  return { name: e.RoomNumber, id: e.Id };
                }),
                HostList: partners,
                DateStart: filtersCTX.filters.estockFilters.DateStart,
                DateEnd: filtersCTX.filters.estockFilters.DateEnd,
              },
              actions: {
                hideSubForm: () => {
                  subFormCTX.hideSubForm(id);
                  Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
                    element.className = '';
                  });
                },
              },
              draggable: true, // Définissez-le en fonction de vos besoins
              fullWidth: false, // Définissez-le en fonction de vos besoins
              fullHeight: false, // Définissez-le en fonction de vos besoins
              translatedTitle: 'Envoi de stock',
              x: 'calc(50% - 200px)',
              y: '20vh',
            };

            // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
            subFormCTX.setSubForm((prevSubForm) => ({
              ...prevSubForm,
              listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
            }));
          }}
        >
          Tous
        </Button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners, resources]);
  const renderResource = useCallback(
    (resource: Room) => {
      return (
        <Tooltip
          placement="right"
          title={<>{resource.RoomTypeDescription === undefined ? resource.RoomNumber : resource.RoomTypeDescription}</>}
        >
          <div
            style={{
              height: '60px',
              color: 'black',
              borderTop: resource.RoomType === 'TOTAL' ? '10px solid #666' : 0,

              justifyContent: 'flex-start',
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
            }}
            className="toto"
            id={'Encap' + resource.Id}
          >
            <Button
              disabled={resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')) === 'TOTAL'}
              onClick={() => {
                if (resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')) !== 'TOTAL') {
                  const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
                  const newSubForm = {
                    id: id, // ou utilisez un ID unique
                    component: EnvoiStock, // Remplacez par le composant que vous souhaitez afficher
                    data: {
                      RoomType: resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')),
                      RoomTypeList: resources.map((e) => {
                        return { name: e.RoomNumber, id: e.Id };
                      }),
                      IdRoomType: resource.Id,
                      HostList: partners,
                      IdHost: currentPartner,
                      DateStart: filtersCTX.filters.estockFilters.DateStart,
                      DateEnd: filtersCTX.filters.estockFilters.DateEnd,
                    }, // Les données que vous souhaitez transmettre au composant
                    actions: {
                      hideSubForm: () => {
                        subFormCTX.hideSubForm(id);
                        Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
                          element.className = '';
                        });
                      },
                    },
                    draggable: true, // Définissez-le en fonction de vos besoins
                    fullWidth: false, // Définissez-le en fonction de vos besoins
                    fullHeight: false, // Définissez-le en fonction de vos besoins
                    translatedTitle: 'Envoi de stock',
                    x: 'calc(50% - 200px)',
                    y: '20vh',
                  };
                  // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
                  subFormCTX.setSubForm((prevSubForm) => ({
                    ...prevSubForm,
                    listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
                  }));
                }
              }}
              style={{
                width: '100%',
                cursor: resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')) !== 'TOTAL' ? 'pointer' : 'default',
                border: resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')) !== 'TOTAL' ? '1px solid #333' : 0,
                padding: '10px',
                borderRadius: '5px',
                backgroundColor:
                  resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr')) !== 'TOTAL' ? '#f0f0f0' : 'transparent',
                color:
                  dataFactoryCTX.DataDictionnary?.Rooms?.find((e) => e.RoomType === resource.RoomType)?.TypeColor ??
                  'black',
              }}
            >
              <i className="fas fa-bed" style={{ margin: '5px' }}></i>
              {resource.RoomNumber.replace('[VIRTUAL]', t('labels.sr'))}
            </Button>
          </div>
        </Tooltip>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPartner, dataFactoryCTX.DataDictionnary?.Rooms, partners, resources, t],
  );

  return (
    <>
      {/*
      {isMobileOnly && isPortrait && groupedEvenet && (
        <>
          <div className="row">
            <div className="col-lg-12 col-12">
              {(isMobile || isTablet) && (
                <div
                  className={`small-box`}
                  style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
                >
                  <div className="inner">
                    <EstockFilters></EstockFilters>
                  </div>
                </div>
              )}
            </div>
          </div>
          <TabView>
            {partners?.map((e) => {
              return (
                <TabPanel header={e.name}>
                  <div className="row">
                    <div style={{ width: '100%', padding: '10px', justifyContent: 'center', display: 'flex' }}>
                      {' '}
                      {renderResourceHeader()}
                    </div>

                    {Object.keys(groupedEvenet).map((date) => (
                      <div className="col-lg-3 col-6">
                        <div className={`small-box bg-gradient-light`}>
                          <div
                            className={`bg-gradient-light}`}
                            style={{
                              height: '5px',
                              width: '100%',
                              borderRadius: 0,
                              borderTopLeftRadius: '8px',
                              borderTopRightRadius: '8px',
                            }}
                          ></div>
                          <div className="inner">
                            <h5> {getDateFormat(date)}</h5>
                          </div>
                          {resources.map((e) => {
                            return (
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '65%' }}>{renderResource(e)}</div>
                                <div style={{ width: '35%', display: 'block', position: 'relative' }}>
                                  {scheduleEvent(null, groupedEvenet[date][e.RoomNumber])}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              );
            })}
          </TabView>
        </>
      )}

      */}
      {/*   {((isMobileOnly && !isPortrait) || isTablet || isDesktop || (isMobileOnly && isLandscape)) && ( */}
      <>
        <div className="row">
          <div className="col-lg-12 col-12">
            {(isMobile || isTablet) && (
              <div
                className={`small-box`}
                style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
              >
                <div className="inner">{<EstockFilters></EstockFilters>}</div>
              </div>
            )}
          </div>
        </div>
        <CustomTimelineRow>
          <TabView
            activeIndex={partners?.findIndex((e) => e.id === currentPartner?.id)}
            onTabChange={(e) => {
              if (partners) {
                setCurrentPartner(partners[e.index]);
              }
            }}
          >
            {partners?.map((e) => {
              return (
                <TabPanel header={e.name}>
                  <Eventcalendar
                    theme="material"
                    themeVariant="light"
                    locale={
                      userCTX.authenticationInfos.language === 'fr'
                        ? localeFr
                        : userCTX.authenticationInfos.language === 'en'
                          ? localeEn
                          : userCTX.authenticationInfos.language === 'es'
                            ? localeEs
                            : userCTX.authenticationInfos.language === 'de'
                              ? localeDe
                              : userCTX.authenticationInfos.language === 'ma'
                                ? localeAr
                                : userCTX.authenticationInfos.language === 'cn'
                                  ? localeZh
                                  : ''
                    }
                    renderResource={renderResource}
                    renderResourceHeader={renderResourceHeader}
                    rtl={userCTX.authenticationInfos.language === 'ma' ? true : false}
                    showEventTooltip={false}
                    data={event}
                    onDayHoverIn={() => null}
                    cssClass={'estock' + darkMode ? 'darkmode' : 'lightmode'}
                    eventOverlap={false}
                    view={{
                      timeline: {
                        type: 'day',
                        virtualScroll: false,
                        resolutionHorizontal: 'day',
                        currentTimeIndicator: false,
                        size: 21,
                        eventList: true,
                      },
                    }}
                    renderScheduleEvent={scheduleEvent}
                    refDate={moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD')}
                    resources={resources}
                    selectedDate={moment(filtersCTX.filters.estockFilters.DateStart).format('YYYY-MM-DD')}
                    renderDay={renderDay}
                  ></Eventcalendar>
                </TabPanel>
              );
            })}
          </TabView>
        </CustomTimelineRow>
      </>
      {/*    )}  */}
    </>
  );
};

export default EStockPage;
