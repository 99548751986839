import React, { useEffect } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSubForm } from '@app/modules/SubFormContext';
import moment from 'moment-timezone';

const PrivateRoute = () => {
  const userCTX = useUser();
  const isLoggedIn = userCTX.authenticationInfos.user;
  const wsCTX = useWs();
  const location = useLocation();
  const subFormCTX = useSubForm();
  useEffect(() => {
    if (userCTX.authenticationInfos.selectedHotel && wsCTX.isConnected && wsCTX.isInHotel) {
      wsCTX.Send({
        Date: moment(Date.now()).format('YYYY-MM-DD'),
        RecipientHotelId: userCTX.authenticationInfos.selectedHotel?.IdHotel.toString() as string,
        SenderUserId: userCTX.authenticationInfos.user?.Id.toString() as string,
        MessageType: 1,
        Message: location.pathname,
      });
    }

    for (let i = 0; i < subFormCTX.subForm.listOfSubformsDisplayed.length; i++) {
      subFormCTX.hideSubForm(subFormCTX.subForm.listOfSubformsDisplayed[i].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userCTX.authenticationInfos.selectedHotel]);

  return isLoggedIn ? (
    <div id="root2">
      {' '}
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
