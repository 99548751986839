import React, { useEffect, useState } from 'react';

import TypeDisponibility from '@app/components/Common/popup/typeDisponibility';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import { ZoneEvent } from '@app/types/Planning/zoneEvent';
import { shortDateToLocaleString } from '@app/utils/dates';
import { formatDate } from '@mobiscroll/react';
import { MbscCalendarDayData } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types.public';
import moment from 'moment';
import { ContextMenu } from 'primereact/contextmenu';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';
import LibreForm from '../popup/libreForm';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { hexToRgba } from '@app/utils/themes';

const CustomDay = ({
  args,
  zoneEvents,
  date,
  caller,
}: {
  args: MbscCalendarDayData;
  zoneEvents: ZoneEvent[] | null; // Replace 'YourEventType' with your actual event type
  date: Date;
  caller: string;
}) => {
  const filtersCTX = useFilters();
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const userCTX = useUser();
  const subFormCTX = useSubForm();
  const userLocale = userCTX.authenticationInfos.language;
  const options = { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' } as Intl.DateTimeFormatOptions;
  const [targets, setTargets] = useState<any>([]);
  const isColored = filtersCTX.filters.availabiltyFilters.ColoredDates?.some((d: Date) =>
    moment(d).isSame(date, 'day'),
  );
  const eventInDate = zoneEvents?.find(
    (e) =>
      moment(e.Dates.DateStart as string).dayOfYear() <= moment(args.date).dayOfYear() &&
      moment(e.Dates.DateEnd as string).dayOfYear() >= moment(args.date).dayOfYear(),
  );
  const handleDragStart = (e: any, date: Date) => {
    if (filtersCTX) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        availabiltyFilters: {
          ...prevState.availabiltyFilters,
          DateStart: date,
          ColoredDates: [date],
        },
      }));
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault(); // Nécessaire pour permettre le drop
    setTargets([...targets, e]);

    const { DateStart } = filtersCTX.filters.availabiltyFilters;

    // Vérifie que la date de début est définie
    if (DateStart) {
      const start = moment(DateStart);
      const end = moment(date);

      // Vérifie que la date survolée est après la date de début
      if (end.isSameOrAfter(start)) {
        // Obtenez toutes les dates entre la date de début et la date actuellement survolée
        const datesBetween: Date[] = [];
        let currentDate = start.clone();
        while (currentDate.isSameOrBefore(end, 'day')) {
          datesBetween.push(currentDate.clone().toDate());
          currentDate.add(1, 'day');
        }

        // Mettre à jour les filtres pour colorer ces dates dynamiquement
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          availabiltyFilters: {
            ...prevState.availabiltyFilters,
            ColoredDates: datesBetween, // Sauvegarder les dates colorées
          },
        }));
      }
    }
  };
  const showLibre = (DateStart: Date, DateEnd: Date) => {
    if (subFormCTX.subForm.listOfSubformsDisplayed.find((e) => e.id === 666)) {
      return;
    }
    const id = 666;
    const newSubForm = {
      id: id,
      component: LibreForm,
      data: {
        startDate: DateStart,
        endDate: DateEnd,
      },
      actions: {
        hideSubForm: () => {
          subFormCTX.hideSubForm(id);
          Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
            element.className = '';
          });
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            availabiltyFilters: {
              DateStart: null,
              DateEnd: null,
              hasBeenModified: false,
              needReload: false,
              ColoredDates: [],
            },
          }));
        },
      },
      showBlackdiv: true,
      width: isMobile
        ? isTablet
          ? isPortrait
            ? '85vw'
            : 'auto'
          : isPortrait //phone
            ? '100vw'
            : 'auto'
        : 'auto',
      draggable: true,
      fullWidth: false,
      fullHeight: isMobileOnly ? true : false,
      translatedTitle: 'Disponibilité par type',
      x: 'calc(50% - 400px)',
      y: isMobileOnly ? 0 : '20vh',
    };

    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const handleDrop = (e: any, date: Date) => {
    if (
      filtersCTX.filters.availabiltyFilters.DateStart !== null &&
      filtersCTX.filters.availabiltyFilters.DateStart < date
    ) {
      if (filtersCTX) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          availabiltyFilters: {
            ...prevState.availabiltyFilters,
            DateEnd: date,
          },
        }));
      }
      showLibre(filtersCTX.filters.availabiltyFilters.DateStart as Date, date);
    }
  };

  const planningCTX = usePlanning();
  const [t] = useTranslation();
  const formattedDate = date.toLocaleDateString(userLocale, options);
  const items = [
    { label: 'Ajouter une nouvelle zone tarifaire', icon: 'fa-solid fa-file-circle-plus' },
    { label: 'Tableau des zones', icon: 'fa-regular fa-calendar' },
    { label: 'Affectation des chambres', icon: 'fa-solid fa-bed' },
    { label: 'Tarifs distribués - prix', icon: 'fa-solid fa-sliders' },
    { label: 'Tarifs distribués - modification en masse', icon: 'fa-solid fa-sliders' },
  ];
  return (
    <>
      <Tooltip
        title={
          eventInDate !== undefined && (
            <>
              {' '}
              {formattedDate}
              <br />
              {eventInDate.Title && (
                <>
                  {eventInDate.Title}
                  <br />
                </>
              )}
              {eventInDate.Description && (
                <>
                  {eventInDate.Description}
                  <br />
                </>
              )}
              {eventInDate.Condition}
              <br />
              {shortDateToLocaleString(
                moment(eventInDate.Dates.DateStart).toDate(),
                userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
              ) +
                ' - ' +
                shortDateToLocaleString(
                  moment(eventInDate.Dates.DateEnd).toDate(),
                  userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                )}
            </>
          )
        }
      >
        <div
          //     onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
          className={'D' + new Date(date).getUTCDate().toString()}
          style={{
            backgroundColor: isColored
              ? '#ffcccb'
              : eventInDate !== undefined
                ? (eventInDate.Background ?? '')
                : '#c0c0c0',
            color: eventInDate !== undefined ? (eventInDate.Foreground ?? '') : '',
            fontWeight: 'bold',
            fontSize: '12pt',
            zIndex: 999,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              height: filtersCTX.filters.planningFilters.ForceOneDay ? '48px' : '36px',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
            draggable="true"
            onDragStart={(event) => handleDragStart(event, date)}
            onDragExit={() =>
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  ...prevState.availabiltyFilters,
                  ColoredDates: [], // Sauvegarder les dates colorées
                },
              }))
            }
            onDragEnd={() =>
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                availabiltyFilters: {
                  ...prevState.availabiltyFilters,
                  ColoredDates: [], // Sauvegarder les dates colorées
                },
              }))
            }
            onDragOver={(event) => handleDragOver(event)}
            onDrop={(event) => handleDrop(event, date)}
            onClick={() => {
              if (caller === 'Planning') {
                userCTX.setForceLoading(true).then(() => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    planningFilters: {
                      ...prevState.planningFilters,
                      DateStart: new Date(date).getTime(),
                      DateEnd: moment(new Date(date)).add(21, 'day').toDate().getTime(),
                    },
                  }));

                  planningCTX.getPlanning(filtersCTX.filters.planningFilters);

                  planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                  userCTX.setForceLoading(false);
                });
              } else if (caller === 'estock') {
                userCTX.setForceLoading(true).then(() => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    estockFilters: {
                      ...prevState.estockFilters,
                      DateStart: new Date(date).getTime(),
                      DateEnd: moment(new Date(date)).add(21, 'day').toDate().getTime(),
                    },
                  }));

                  userCTX.setForceLoading(false);
                });
              }
            }}
          >
            {formatDate('DDD DD', date, {
              dayNamesShort: Object.values(t('calendar.dayNamesShort', { returnObjects: true })),
            })}
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default CustomDay;
