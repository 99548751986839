import React, { memo, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { PlanningLine } from '@app/types/types';
import { mdiCashMultiple, mdiCreditCardClock } from '@mdi/js';
import Icon from '@mdi/react';
import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react';
import moment from 'moment-timezone';
import { ContextMenu } from 'primereact/contextmenu';
import { useSelector } from 'react-redux';
import { useUser } from '@app/modules/user/UserContext';

import handClick from '@assets/handClick.svg';
import MajStock from '../Stock/MajStock';
import { Tooltip } from '@mui/material';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { hexToRgba } from '@app/utils/themes';

const Reservation = memo(
  ({
    data,
    PlanningDate,
    original,
    backgroundColor,
    foregroundColor,
    groupId,
  }: {
    data?: MbscCalendarEventData;
    original?: PlanningLine;
    PlanningDate: string | undefined;
    backgroundColor?: string;
    foregroundColor?: string;
    groupId?: string;
  }) => {
    const [isHovered, setIsHovered] = useState(false);

    const currentBackgroundColor = isHovered ? foregroundColor : backgroundColor;
    const currentForegroundColor = isHovered ? backgroundColor : foregroundColor;
    const filtersCTX = useFilters();
    const ev = original ?? (data?.original as PlanningLine); // Replace 'PlanningLine' with your actual type
    const subFormCTX = useSubForm();
    const darkMode = useSelector((state: RootState) => state.ui.darkMode);
    const dataFactoryCTX = useDataFactory();
    if (ev !== undefined) {
      //Dessin des totaux avec valeurs
      if (ev?.totalType) {
        return (
          <div
            style={{
              cursor: 'default',
              width: 'calc(100% - 1px)',
              height: '28px',
              backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey300,
              color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
              fontSize: '10pt',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              borderBottom: '1px solid #ccc',
            }}
          >
            {ev.DisplayName !== '0' && ev.DisplayName !== '' && (
              <div
                style={{
                  cursor: 'default',
                  textAlign: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  backgroundColor: hexToRgba(ev.foreground),
                  border: '1px solid black',
                  width: '22px',
                  height: '22px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {ev.DisplayName}
              </div>
            )}
          </div>
        );
      }

      if (ev?.stockType) {
        return (
          <Tooltip
            placement="right"
            title={
              <>
                <div>
                  {ev.foreground === 'disabled' && <div>Le stock est fermé</div>}
                  {ev.foreground !== 'disabled' && (
                    <div>
                      Nb. {ev.RoomTypeList && ev.RoomTypeList.find((e) => e.id === ev.IdRoomType)?.name} distribuées :{' '}
                      {ev.Distribuee}
                    </div>
                  )}
                </div>
                <div>
                  Nb. {ev.RoomTypeList && ev.RoomTypeList.find((e) => e.id === ev.IdRoomType)?.name} disponibles
                  distribuables : {ev.Disponible}
                </div>

                {dataFactoryCTX.PMSSettings?.ManageAllotments && (
                  <>
                    <br /> <div>Nb. allotements restants : {ev.Alotement}</div>
                    <div>
                      Nb. disponible si tous les allotements restants sont réservés:{' '}
                      {((ev.Disponible ?? 0) as number) - ((ev.Alotement ?? 0) as number)}
                    </div>
                  </>
                )}
              </>
            }
          >
            <div
              onClick={() => {
                if (ev.Type !== 'TOTAL') {
                  const id = 999;
                  const newSubForm = {
                    id: id, // ou utilisez un ID unique
                    component: MajStock, // Remplacez par le composant que vous souhaitez afficher
                    data: {
                      RoomType: ev.Type,
                      IdRoomType: ev.IdRoomType,
                      Disponible: ev.Disponible,
                      DateStart: ev.DateStart,
                      DateEnd: ev.DateEnd,
                      HostList: ev.HostList,
                      RoomTypeList: ev.RoomTypeList,
                      IdHost: ev.IdHost,
                    }, // Les données que vous souhaitez transmettre au composant
                    actions: {
                      hideSubForm: () => {
                        subFormCTX.hideSubForm(id);
                        Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
                          element.className = '';
                        });
                      },
                    },
                    draggable: true, // Définissez-le en fonction de vos besoins
                    fullWidth: false, // Définissez-le en fonction de vos besoins
                    fullHeight: false, // Définissez-le en fonction de vos besoins
                    translatedTitle: 'Mettre à jour le stock',
                    x: 'calc(50% - 200px)',
                    y: '20vh',
                  };
                  // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
                  subFormCTX.setSubForm((prevSubForm) => ({
                    ...prevSubForm,
                    listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
                  }));
                }
              }}
              style={{
                cursor: ev.Type !== 'TOTAL' ? 'pointer' : 'default',
                width: 'calc(100% - 1px)',
                color: colors.colorGreyAdminLTE,
                fontSize: '16pt',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                alignContent: 'center',
                justifyContent: 'center',
                borderTop: ev.isCommunicating === true ? '10px solid #666' : 0,
                borderBottom: '1px solid #ccc',
                fontWeight: ev.isTextBold ? 'bolder' : 'normal',

                backgroundColor: ev.foreground !== 'disabled' ? ev.foreground : 'silver',
                height: '57px',
              }}
            >
              <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    fontWeight: ev?.isTextBold ? 'bold' : 'normal',
                    textAlign: 'center',
                    borderRadius: '5px',
                  }}
                >
                  {ev.foreground === 'disabled' && ev.Type !== 'TOTAL' && (
                    <i
                      style={{
                        color: 'darkorange',

                        zIndex: 10,
                      }}
                      className="fas fa-lock"
                    ></i>
                  )}
                  {ev.Distribuee !== '' && ev.Distribuee}
                  {ev.foreground === 'disabled' && ev.Type === 'TOTAL' ? 0 : ''}
                </div>
                {dataFactoryCTX.PMSSettings?.ManageAllotments && (
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      fontSize: '8pt',
                      top: ev.isCommunicating ? '10px' : '3px',
                      right: '3px',
                      position: 'absolute',
                    }}
                  >
                    {' '}
                    {ev.Alotement !== '' && ev.Alotement}
                  </div>
                )}
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    fontSize: '8pt',
                    top: ev.isCommunicating ? '40px' : '32px',
                    right: '4px',
                    position: 'absolute',
                  }}
                >
                  {ev.Disponible !== '' && ev.Disponible}
                </div>
              </div>
            </div>
          </Tooltip>
        );
      }
      const handleHoverIn = () => {
        if (groupId) {
          const groupElements = document.querySelectorAll(`[data-group-id="${groupId}"]`);
          groupElements.forEach((el) => {
            const element = el as HTMLElement;

            const originalBgColor = element.getAttribute('data-background-color');
            const originalFgColor = element.getAttribute('data-foreground-color');

            if (originalBgColor && originalFgColor) {
              element.style.backgroundColor = originalFgColor; // Inverse les couleurs
              element.style.color = originalBgColor;
              const badges = element.querySelectorAll('.badge, .fa, .mdi, img');
              badges.forEach((badge) => {
                const badgeEl = badge as HTMLElement;
                badgeEl.style.color = originalBgColor; // Appliquer la couleur de fond à l'icône
              });
            }
          });
        }
      };

      const handleHoverOut = () => {
        if (groupId) {
          const groupElements = document.querySelectorAll(`[data-group-id="${groupId}"]`);
          groupElements.forEach((el) => {
            const element = el as HTMLElement;
            const originalBgColor = element.getAttribute('data-background-color');
            const originalFgColor = element.getAttribute('data-foreground-color');

            if (originalBgColor && originalFgColor) {
              element.style.backgroundColor = originalBgColor; // Rétablit les couleurs d'origine
              element.style.color = originalFgColor;
              const badges = element.querySelectorAll('.badge, .fa, .mdi, img');
              badges.forEach((badge) => {
                const badgeEl = badge as HTMLElement;
                badgeEl.style.color = ev.CouleurEvent ?? ''; // Appliquer la couleur de fond à l'icône
              });
            }
          });
        }
      };
      //dessin des evenements reservations
      return (
        <>
          <div
            //  onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
            onMouseEnter={handleHoverIn}
            onMouseLeave={handleHoverOut}
            data-group-id={groupId}
            data-background-color={backgroundColor}
            data-foreground-color={foregroundColor}
            style={{
              borderRadius: '4px',
              border: '2px solid #333',
              height: '22px',
              margin: '1px',
              marginTop: '3px',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
              alignItems: 'center',
              backgroundColor: backgroundColor,
              color: foregroundColor,
            }}
          >
            <div
              style={{
                borderTop:
                  '3px solid' + moment(ev.End).format('YYYYMMDD') ===
                  moment(new Date(PlanningDate as string))
                    .add(1, 'day')
                    .format('YYYYMMDD')
                    ? hexToRgba(currentForegroundColor ?? '')
                    : hexToRgba(currentBackgroundColor ?? ''),
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {ev.Status === 'D' && (
                  <span>
                    <i className="fa-solid fa-plane-departure" style={{ color: 'darkgreen' }}></i>
                  </span>
                )}
                {ev.IsOutOfOrder === true && (
                  <i
                    style={{ color: 'red', marginLeft: '5px', marginRight: '5px', width: '16px' }}
                    className="fas fa-exclamation-triangle"
                  ></i>
                )}

                {ev.GuaranteeStatus === 1 && ev.PastilleDisplayed && (
                  <Icon
                    style={{
                      marginLeft: '2px',
                      marginRight: '2px',
                      marginTop: '-7px',
                    }}
                    path={mdiCashMultiple}
                    size={0.7}
                  />
                )}
                {ev.GuaranteeStatus === 2 && ev.PastilleDisplayed && (
                  <Icon
                    style={{
                      marginLeft: '2px',
                      marginRight: '2px',
                      marginTop: '-7px',
                    }}
                    path={mdiCreditCardClock}
                    size={0.7}
                  />
                )}
                {ev.GuaranteeStatus === 3 && ev.PastilleDisplayed && (
                  <i
                    style={{ marginLeft: '5px', marginRight: '5px', width: '16px' }}
                    className="fas fa-credit-card"
                  ></i>
                )}
                {ev.GuaranteeStatus === 4 && ev.PastilleDisplayed && (
                  <i style={{ marginLeft: '5px', marginRight: '5px', width: '16px' }} className="fas fa-circle"></i>
                )}
                {ev.GuaranteeStatus === 6 && ev.PastilleDisplayed && (
                  <img
                    src={handClick}
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                      width: '16px',
                      marginTop: '-5px',
                    }}
                  />
                )}
                {!filtersCTX.filters.planningFilters.ForceOneDay && (
                  <span
                    style={{
                      zIndex: 10,
                      marginLeft: ev.PastilleDisplayed === false ? '10px' : '',
                      position: 'relative',
                      fontWeight: ev.IsTextBold ? 'bolder' : 'normal',
                      fontSize: '15px',
                    }}
                  >
                    {ev.DisplayName}
                  </span>
                )}
                {filtersCTX.filters.planningFilters.ForceOneDay && (
                  <>
                    <span
                      style={{
                        zIndex: 10,

                        position: 'relative',
                        fontWeight: 'bolder',
                        width: '300px',
                        borderRight: '1px solid black',
                        display: 'inline-block',
                        lineHeight: '18px',
                      }}
                    >
                      {ev.DisplayName}
                    </span>
                    <span
                      style={{
                        fontWeight: 'bolder',
                        borderRight: '1px solid black',
                        textAlign: 'center',
                        width: 'auto',
                        display: 'inline-block',

                        padding: '10px',
                      }}
                    >
                      {ev?.Memo?.replace('<br/>', '')}
                    </span>
                  </>
                )}
                {ev.IsGroup === true && (
                  <i
                    style={{ color: 'red', position: 'absolute', right: '10px', zIndex: 0, top: '8px' }}
                    className="fas fa-users"
                  ></i>
                )}
                {ev.Bloque === true && (
                  <i
                    style={{
                      color: 'darkorange',
                      position: 'absolute',
                      right: ev.IsGroup === true ? '35px' : '10px',
                      zIndex: 10,
                      top: '8px',
                    }}
                    className="fas fa-lock"
                  ></i>
                )}
              </span>
            </div>
          </div>
        </>
      );
    } else {
    }
  },
  (prevProps, nextProps) => {
    return (
      prevProps.PlanningDate === nextProps.PlanningDate &&
      prevProps.data === nextProps.data &&
      prevProps.original === nextProps.original
    );
  },
);

Reservation.displayName = 'Reservation';

export default Reservation;
