import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';

import { ContextMenu } from 'primereact/contextmenu';

import BaseForm from '@components/Common/popup/BaseForm';

// Define the type for your params
export type WindowType = {
  listOfWindowsDisplayed: {
    id: number;
    component: React.ComponentType<any>;
    data: any;
    actions: any;
    translatedTitle?: React.ReactElement | string;
    draggable: boolean;
    fullWidth: boolean;
    fullHeight: boolean;
    x?: number | string;
    y?: number | string;
    width?: number | string;
    height?: number | string;
    border?: string;
    zIndex?: number;
    opacity?: number;
    classToAdd?: string;
    showBlackdiv?: boolean;
    showCloseBtn?: boolean;
    maxHeight?: string;
  }[];
};

// Define the interface for ParamsContextState
interface WindowContextState {
  window: WindowType;

  setWindow: React.Dispatch<React.SetStateAction<WindowType>>;
  hideWindow: (id: number) => void; // Add this
  hideWindowFromScreen: (id: number) => void;
}

// Create a context to hold your params with default values
export const WindowContext = createContext<WindowContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type WindowProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const WindowProvider: React.FC<WindowProviderProps> = (props) => {
  const [window, setWindow] = useState<WindowType>({ listOfWindowsDisplayed: [] });

  const hideWindow = (id: number) => {
    setWindow((prevWindow) => ({
      ...prevWindow,
      listOfWindowsDisplayed: prevWindow.listOfWindowsDisplayed.filter((form) => form.id !== id),
    }));
  };

  const hideWindowFromScreen = (id: number) => {
    setWindow((prevWindow) => {
      const formIndex = prevWindow.listOfWindowsDisplayed.findIndex((e) => e.id === id);
      if (formIndex !== -1) {
        const updatedForms = [...prevWindow.listOfWindowsDisplayed];
        updatedForms[formIndex] = { ...updatedForms[formIndex], classToAdd: 'hideWindow' };
        return { ...prevWindow, listOfWindowsDisplayed: updatedForms };
      }
      return prevWindow;
    });
  };

  return (
    <WindowContext.Provider value={{ window, setWindow, hideWindow, hideWindowFromScreen }}>
      {window.listOfWindowsDisplayed.map((form, index) => {
        return (
          <BaseForm
            id={form.id}
            data={form.data}
            translatedTitle={<>{form?.translatedTitle}</>}
            key={index}
            component={form.component}
            actions={form.actions}
            draggable={form.draggable}
            fullWidth={form.fullWidth}
            fullHeight={form.fullHeight}
            x={form.x}
            width={form.width}
            height={form.height}
            y={form.y}
            border={form.border}
            zIndex={form.zIndex}
            opacity={form.opacity}
            classToAdd={form.classToAdd}
            showBlackdiv={form.showBlackdiv}
            showCloseBtn={form.showCloseBtn}
            maxHeight={form.maxHeight}
          />
        );
      })}
      {props.children}
    </WindowContext.Provider>
  );
};

// Create a custom hook to access the params
export function useWindow(): WindowContextState {
  const windowContext = useContext(WindowContext);
  if (windowContext === undefined) {
    throw new Error('useWindow must be used within a WindowProvider');
  }
  return windowContext;
}
