import React from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { Button } from 'primereact/button';
import { useMail } from '@app/modules/Mail/MailContext';
import { useUser } from '@app/modules/user/UserContext';

const HeaderMail = () => {
  const mailCTX = useMail();
  const userCTX = useUser();

  return (
    <div
      style={{
        height: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '10px',
        borderBottom: '2px solid #ccc',
      }}
    >
      <Button
        style={{ height: '32px', fontSize: '0.8rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        className="mr-2"
        id="newBtn"
        onClick={() => {
          mailCTX?.setSelectedMail(null);
          mailCTX.setCreationMode(true);
          mailCTX.setCreationDailyMode(false);
        }}
      >
        <span
          style={{
            height: '32px',
            fontSize: '0.8rem',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i style={{ paddingRight: '10px' }} className="far fa-envelope"></i> Nouveau message
        </span>
      </Button>
      <Button
        disabled={userCTX.authenticationInfos.selectedHotel === null ? true : false}
        id="creationBtn"
        style={{ height: '32px', fontSize: '0.8rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        className="mr-2"
        onClick={() => {
          mailCTX?.setSelectedMail(null);
          mailCTX.setCreationDailyMode(true);
          mailCTX.setCreationMode(false);
        }}
      >
        <span
          style={{
            height: '32px',
            fontSize: '0.8rem',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i style={{ paddingRight: '10px' }} className="pi pi-fw pi-sun"></i> Message du jour
        </span>
      </Button>
      <Button
        id="replyBtn"
        disabled={mailCTX.SelectedMail === null || mailCTX.currentBox !== '1'}
        style={{ height: '32px', fontSize: '0.8rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        className="mr-2"
        onClick={() => {
          mailCTX.setUserToSend([
            {
              IdUser: mailCTX.SelectedMail?.IdUserFrom as string,
              IdHotel: mailCTX.SelectedMail?.IdHotelFrom as number,
            },
          ]);
          mailCTX.setCreationMode(true);

          //reply
        }}
      >
        <span
          style={{
            height: '32px',
            fontSize: '0.8rem',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i style={{ paddingRight: '10px' }} className="fas fa-reply"></i> Répondre
        </span>
      </Button>
      <Button
        id="deleteBtn"
        disabled={
          mailCTX.SelectedMail === null ||
          mailCTX.currentBox === '1-1' ||
          mailCTX.currentBox === '2-1' ||
          ('CanDelete' in mailCTX.SelectedMail && !mailCTX.SelectedMail.CanDelete)
        }
        style={{ height: '32px', fontSize: '0.8rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        className="mr-2"
        onClick={() => {
          mailCTX.currentBox === '1'
            ? mailCTX.deleteMessageInbox(mailCTX.SelectedMail?.Id as string)
            : mailCTX.currentBox === '0'
              ? mailCTX.deleteMessageDaily(mailCTX.SelectedMail?.Id as string)
              : mailCTX.deleteMessageOutbox(mailCTX.SelectedMail?.Id as string);
          mailCTX.setSelectedMail(null);
        }}
      >
        <span
          style={{
            height: '32px',
            fontSize: '0.8rem',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i style={{ paddingRight: '10px' }} className="fas fa-trash"></i> Supprimer
        </span>
      </Button>
      <Button
        id="readAllBtn"
        onClick={() => {
          mailCTX.inboxMessagesUnread?.map((mail) => mailCTX.markAsRead(mail.Id as string));
        }}
        disabled={mailCTX.currentBox !== '1' || mailCTX.inboxMessagesUnreadCount === 0}
        style={{ height: '32px', fontSize: '0.8rem', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        className="mr-2"
      >
        <span
          style={{
            height: '32px',
            fontSize: '0.8rem',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i style={{ paddingRight: '10px' }} className="far fa-envelope-open"></i> Marquer tous les éléments comme lus
        </span>
      </Button>
    </div>
  );
};

export default HeaderMail;
