import colors from '@app/styles/colors';
import { Button } from 'primereact/button';
import { ReactElement } from 'react';

export type InformationModalProps = {
  data: any;
  actions: any;
};

const InformationModal: React.FC<InformationModalProps> = ({ data, actions }) => {
  return (
    <div>
      <p
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          padding: '20px',
          fontSize: '18px',
          alignItems: 'center',
          fontWeight: 'bold',
        }}
      >
        {data.iconComponent} {data.description}
      </p>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', padding: '20px' }}>
        <Button
          onClick={() => actions.onConfirm()}
          style={{ width: '35%', backgroundColor: colors.colorErrorMain, border: colors.colorErrorDark }}
          icon="fa-solid fa-reply-all"
        />
      </div>
    </div>
  );
};

export default InformationModal;
