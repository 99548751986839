import React from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';

interface messageList {
  sender: string;
  recipient: string;
  message: string;
  date: string;
}

interface props {
  recipient: string;
}
const MessageList: React.FC<props> = (props) => {
  const userCTX = useUser();
  const wsCtx = useWs();
  return (
    <div
      style={{
        width: '250px',
        height: '300px',
        border: '2px solid silver',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        overflow: 'auto',
      }}
    >
      {wsCtx.messages &&
        wsCtx?.messages[props.recipient]?.map((e, i) => {
          return (
            <div
              key={i}
              className={'message' + (userCTX.authenticationInfos.user?.Id === e.SenderUserId ? ' message-self' : '')}
            >
              <div className="message-text">{e.Message}</div>
            </div>
          );
        })}
    </div>
  );
};

export default MessageList;
