export const config = {
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  LOGGER_API: import.meta.env.VITE_LOGGER_API,
  VERSION: import.meta.env.VITE_VERSION,
  LOGGER_WEB: import.meta.env.VITE_LOGGER_WEB,
  NODE_ENV: import.meta.env.VITE_NODE_ENV,
  API_URL: import.meta.env.VITE_API_URL,
  WS_URL: import.meta.env.VITE_WS_URL,
  CANMOVEPLANNING: import.meta.env.VITE_CANMOVEPLANNING,
};
