import React, { useState } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { useMail } from '@app/modules/Mail/MailContext';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { isMobile } from 'react-device-detect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer le style de base
import colors from '@app/styles/colors';
import { getDateFormat } from '@app/utils/dates';
import { useUser } from '@app/modules/user/UserContext';
import UsersList from './UsersList';
import { useSubForm } from '@app/modules/SubFormContext';
import { Chip } from '@mui/material';
import { DailyMessage, EmailToSend } from '@app/types/types';
import moment from 'moment-timezone';
import CalendarSelector from '@app/components/Common/calendar/CalendarSelector';

const NewDailyMail = () => {
  const mailCTX = useMail();
  const userCTX = useUser();
  const [content, setContent] = useState('');
  const [subject, setSubjet] = useState('');
  const subFormCTX = useSubForm();
  const handleChange = (value: string) => {
    setContent(value);
  };
  const sendDailyMessage = () => {
    let mail: DailyMessage = {
      Body: content,
      ValidityFrom: moment(mailCTX.activeDate).format('YYYY-MM-DD'),
      ValidityTo: moment(mailCTX.untilDate).format('YYYY-MM-DD'),
      IdUserFrom: userCTX.authenticationInfos.user?.Id.toString() as string,
    };
    mailCTX.addDailyMessage(mail);
    mailCTX.setCreationDailyMode(false);
    mailCTX.getDailyMessage(mailCTX.mailFilters);
  };
  return (
    <div
      style={{
        height: 'calc(100%)',
        width: 'calc(100% - 650px)',
        backgroundColor: '#f6f6f6',
        borderLeft: '1px solid #dfdfdf',
      }}
    >
      <div
        className="small-box-mail bg-gradient-light"
        style={{
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#fff',
          margin: '10px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <span>Actif à partir du</span>
          <Calendar
            inputStyle={{ height: '34px', width: '100px' }}
            showWeek
            style={{ height: '34px', lineHeight: '34px' }}
            value={mailCTX.activeDate}
            dateFormat={getDateFormat(mailCTX.activeDate, userCTX.authenticationInfos.language ?? 'fr')}
            onChange={(e) => mailCTX.setActiveDate(e.value as Date)}
            todayButtonClassName={'hidden'}
            touchUI={isMobile}
            locale={userCTX.authenticationInfos?.language || 'fr'}
          ></Calendar>
          <span>au</span>
          <Calendar
            inputStyle={{ height: '34px', width: '100px' }}
            showWeek
            style={{ height: '34px', lineHeight: '34px' }}
            value={mailCTX.untilDate}
            dateFormat={getDateFormat(mailCTX.untilDate, userCTX.authenticationInfos.language ?? 'fr')}
            onChange={(e) => mailCTX.setUntilDate(e.value as Date)}
            todayButtonClassName={'hidden'}
            touchUI={isMobile}
            locale={userCTX.authenticationInfos?.language || 'fr'}
          ></Calendar>
        </div>
      </div>

      <div
        className="small-box-mail bg-gradient-light"
        style={{
          height: 'calc(100% - 140px)',
          maxHeight: 'calc(100% - 140px)',
          overflow: 'auto',
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: 'red',
          margin: '10px',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ReactQuill
          id="contentMail"
          value={content}
          onChange={handleChange}
          style={{
            backgroundColor: 'white',
            minWidth: '100%',
            minHeight: 'calc(100% - 100px)',
            maxHeight: 'calc(100% - 100px)',
          }}
          theme="snow"
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline'],
              ['link', 'image'],
              [{ align: [] }],
            ],
          }}
          placeholder="Commencez à écrire votre template d'email ici..."
        />
        <Button
          id="sendMail"
          disabled={mailCTX.activeDate === null || mailCTX.untilDate === null || content === ''}
          onClick={() => sendDailyMessage()}
          style={{ position: 'fixed', bottom: 35, right: 20 }}
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default NewDailyMail;
