import React, { useEffect, useState } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import MailItem from './MailItem';
import { useMail } from '@app/modules/Mail/MailContext';
import { Paginator } from 'primereact/paginator';
import { Tree } from 'primereact/tree';
import { HotelMails, simpleRecipient } from '@app/types/types';
import { TreeNode } from 'primereact/treenode';
import { Button } from 'primereact/button';

interface props {
  actions: {
    hideSubForm: () => void;
  };
}
const UsersList: React.FC<props> = ({ actions }) => {
  const mailCTX = useMail();
  const transformData = (data: HotelMails[]): TreeNode[] => {
    const hotelNodes = data.map((hotel, hotelIndex) => ({
      key: `${hotelIndex}`,
      label: hotel.HotelName,
      data: `${hotel.HotelName} Folder`,
      icon: 'fas fa-hotel',
      children: hotel.Users.map((user, userIndex) => ({
        key: `${hotelIndex}-${userIndex}`,
        label: user.DisplayName,
        data: user.Job ? `${user.Job} Document` : 'User Document',
        icon: 'fas fa-user',
      })),
    }));

    // Ajouter un nœud racine qui contiendra tous les hôtels
    return [
      {
        key: 'root',
        label: 'Tous les Hôtels',
        expanded: true,
        data: 'Root Folder',
        icon: 'fas fa-globe',
        children: hotelNodes,
      },
    ];
  };
  const getSelectedRecipients = (
    selectionObject: { [key: string]: { checked: boolean; partialChecked: boolean } } | null,
  ): simpleRecipient[] => {
    if (!selectionObject || !mailCTX.recipients) return [];

    const selectedKeys = Object.keys(selectionObject).filter((key) => selectionObject[key].checked);
    const recipients: simpleRecipient[] = [];

    mailCTX.recipients.forEach((hotel, hotelIndex) => {
      hotel.Users.forEach((user, userIndex) => {
        const key = `${hotelIndex}-${userIndex}`;
        if (selectedKeys.includes(key)) {
          recipients.push({ IdUser: user.Id, IdHotel: hotel.IdHotel }); // Créez des objets simpleRecipient
        }
      });
    });

    return recipients;
  };
  const [transformRecipients, setTransformedRecipients] = useState<TreeNode[]>();

  const initializeSelectedKeys = () => {
    const keys: { [key: string]: { checked: boolean; partialChecked: boolean } } = {};
    let allHotelsChecked = false;
    let anyHotelChecked = false;

    mailCTX.recipients?.forEach((hotel, hotelIndex) => {
      let selectedCount = 0;
      hotel.Users.forEach((user, userIndex) => {
        if (mailCTX.userToSend && mailCTX.userToSend.some((u) => u.IdUser === user.Id)) {
          keys[`${hotelIndex}-${userIndex}`] = { checked: true, partialChecked: false };
          selectedCount++;
        }
      });

      // Si tous les utilisateurs sont sélectionnés, cochez complètement le nœud parent (hôtel)
      // Sinon, marquez-le comme partiellement sélectionné si certains utilisateurs sont sélectionnés
      if (selectedCount === hotel.Users.length) {
        keys[`${hotelIndex}`] = { checked: true, partialChecked: false };
        anyHotelChecked = true;
      } else if (selectedCount > 0) {
        keys[`${hotelIndex}`] = { checked: false, partialChecked: true };
        allHotelsChecked = false;
        anyHotelChecked = true;
      } else {
        allHotelsChecked = false;
      }
    });

    // Définir l'état du nœud racine "Tous les Hôtels"
    if (allHotelsChecked) {
      keys['root'] = { checked: true, partialChecked: false };
    } else if (anyHotelChecked) {
      keys['root'] = { checked: false, partialChecked: true };
    } else {
      keys['root'] = { checked: false, partialChecked: false };
    }

    return keys;
  };

  const [selectedKeys, setSelectedKeys] = useState<{
    [key: string]: { checked: boolean; partialChecked: boolean };
  }>(initializeSelectedKeys);

  useEffect(() => {
    if (mailCTX.recipients) {
      setTransformedRecipients(transformData(mailCTX.recipients));
    }
  }, [mailCTX.recipients]);

  useEffect(() => {
    if (mailCTX.recipients && mailCTX.userToSend) {
      setSelectedKeys(initializeSelectedKeys());
    }
  }, [mailCTX.recipients, mailCTX.userToSend]);
  return (
    <div
      style={{
        height: 'calc(100%)',
        width: '400px',
        backgroundColor: 'white',
        borderBottom: '2px solid #f0f0f0',
        borderRight: '2px solid #f0f0f0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Tree
        style={{ maxHeight: 'calc(100% - 50px)', height: 'calc(100% - 50px)', overflow: 'auto', width: '100%' }}
        value={transformRecipients}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={(e) => {
          const selection = e.value as { [key: string]: { checked: boolean; partialChecked: boolean } } | null;

          setSelectedKeys(selection ?? {});
        }}
        className="w-full md:w-30rem"
      />
      <Button
        id="confirmRecipient"
        icon="fas fa-check"
        style={{ margin: '20px', width: '120px' }}
        onClick={() => {
          mailCTX.setUserToSend(getSelectedRecipients(selectedKeys));
          actions.hideSubForm();
        }}
      >
        <span style={{ paddingLeft: '10px' }}>Valider</span>
      </Button>
    </div>
  );
};

export default UsersList;
