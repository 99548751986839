export function decodeNotificationIdentifier(code: bigint): string[] {
  const activeIdentifiers: string[] = [];
  const bigIntCode = typeof code === 'bigint' ? code : BigInt(code);

  for (const [key, value] of Object.entries(DataChangeNotificationIdentifier)) {
    if ((bigIntCode & value) !== BigInt(0)) {
      activeIdentifiers.push(key);
    }
  }

  return activeIdentifiers;
}
export const DataChangeNotificationIdentifier = {
  None: BigInt(0),
  InternalMessage: BigInt(1) << BigInt(0),
  Parametres: BigInt(1) << BigInt(1),
  AllotmentContracts: BigInt(1) << BigInt(2),
  AllotmentDetails: BigInt(1) << BigInt(3),
  Rooms: BigInt(1) << BigInt(4),
  HostBookingsSubscriptions: BigInt(1) << BigInt(5),
  CodesClient: BigInt(1) << BigInt(6),
  Colors: BigInt(1) << BigInt(7),
  Currencies: BigInt(1) << BigInt(8),
  ExpenseCategory: BigInt(1) << BigInt(9),
  DisplayColors: BigInt(1) << BigInt(10),
  RoomStates: BigInt(1) << BigInt(11),
  Origines: BigInt(1) << BigInt(12),
  BillingInstructions: BigInt(1) << BigInt(13),
  Bookings: BigInt(1) << BigInt(14),
  Segments: BigInt(1) << BigInt(15),
  Rates: BigInt(1) << BigInt(16),
  RoomTypes: BigInt(1) << BigInt(17),
  Titres: BigInt(1) << BigInt(18),
  Users: BigInt(1) << BigInt(19),
  Products: BigInt(1) << BigInt(20),
  Zones: BigInt(1) << BigInt(21),
  PaymentModes: BigInt(1) << BigInt(22),
  eStock: BigInt(1) << BigInt(23),
  Countries: BigInt(1) << BigInt(24),
  Familles: BigInt(1) << BigInt(25),
  TaxRates: BigInt(1) << BigInt(26),
  HostsInstalled: BigInt(1) << BigInt(27),
  RoomTypeCategories: BigInt(1) << BigInt(28),
  DailyMessages: BigInt(1) << BigInt(29),
  BookingDeposits: BigInt(1) << BigInt(30),
  Invoice: BigInt(1) << BigInt(31),
  SubFamilles: BigInt(1) << BigInt(32),
  InvoicingHosts: BigInt(1) << BigInt(33),
  Payment: BigInt(1) << BigInt(34),
  WorkStation: BigInt(1) << BigInt(35),
  NomTarifs: BigInt(1) << BigInt(36),
  WebDispoHotels: BigInt(1) << BigInt(37),
  Opentele: BigInt(1) << BigInt(38),
  ServerState: BigInt(1) << BigInt(39),
  HostName: BigInt(1) << BigInt(40),
  Letters: BigInt(1) << BigInt(41),
  ClosureRunning: BigInt(1) << BigInt(42),
  UsersRole: BigInt(1) << BigInt(43),
  SaleCondition: BigInt(1) << BigInt(44),
  ClosureEnd: BigInt(1) << BigInt(45),
  InvoiceProduits: BigInt(1) << BigInt(46),
  ClosedPeriod: BigInt(1) << BigInt(47),
  JobScheduling: BigInt(1) << BigInt(48),
  HotelProperties: BigInt(1) << BigInt(49),
  HotelPictures: BigInt(1) << BigInt(50),
  KardexAccountType: BigInt(1) << BigInt(51),
  Product_Prices: BigInt(1) << BigInt(52),
  RateCancelCondition: BigInt(1) << BigInt(53),
  PreAuthorizationsCaptured: BigInt(1) << BigInt(54),
  EpaymentTpe: BigInt(1) << BigInt(55),
} as const;
