import { useBookings } from '@app/modules/Booking/BookingsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useUser } from '@app/modules/user/UserContext';
import colors from '@app/styles/colors';
import { getDeltaInDays, shortDateToLocaleString } from '@app/utils/dates';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableSelectionMultipleChangeEvent } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';

interface props {
  data: any;
  actions: any;
}

const GroupModale: React.FC<props> = ({ data, actions }) => {
  const userCTX = useUser();
  const [selectedLine, setSelectedLine] = useState<BookingItem[] | null>(null);
  const [rowClick, setRowClick] = useState(true);
  const bookingCTX = useBookings();
  const planningCTX = usePlanning();
  const filtersCTX = useFilters();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '5px' }}>
      <div>
        {data.updatedValues.Group.ChangeableItems && data.updatedValues.Group.ChangeableItems.length > 0 && (
          <div>
            <span>Appliquer la modification suivante aux chambres : </span>
            <span style={{ color: 'blue' }}>
              Dates du{' '}
              {shortDateToLocaleString(data?.args?.event?.Start, userCTX.authenticationInfos.user?.Culture ?? 'fr')} au{' '}
              {shortDateToLocaleString(data?.args?.event?.End, userCTX.authenticationInfos.user?.Culture ?? 'fr')} =
              {'>'} Du{' '}
              {shortDateToLocaleString(data?.args?.event?.start, userCTX.authenticationInfos.user?.Culture ?? 'fr')} au{' '}
              {shortDateToLocaleString(data?.args?.event?.end, userCTX.authenticationInfos.user?.Culture ?? 'fr')}
            </span>
            <div>
              <DataTable
                rowHover
                stripedRows
                className="normalDT previsionDT"
                value={data.updatedValues?.Group?.ChangeableItems}
                style={{ width: '100%', border: '1px solid silver' }}
                selectionMode={rowClick ? null : 'checkbox'}
                selection={selectedLine}
                onSelectionChange={(e: DataTableSelectionMultipleChangeEvent<any>) => setSelectedLine(e.value)}
              >
                <Column selectionMode="multiple" style={{ width: '3rem' }}>
                  {' '}
                </Column>
                <Column field="RoomNumber" header="Chambre"></Column>
                <Column field="RoomType" header="Type"></Column>
                <Column
                  header="Arrivée"
                  body={(e) =>
                    shortDateToLocaleString(
                      moment(e.DateStart).toDate(),
                      userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                    )
                  }
                ></Column>
                <Column
                  header="Nuits"
                  body={(e) => getDeltaInDays(moment(e.DateStart).toDate(), moment(e.DateEnd).toDate())}
                ></Column>
                <Column
                  header="Départ"
                  body={(e) =>
                    shortDateToLocaleString(
                      moment(e.DateEnd).toDate(),
                      userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                    )
                  }
                ></Column>
                <Column field="Name" header="Occupants"></Column>
                <Column field="Status" header="Statut"></Column>
              </DataTable>
            </div>
          </div>
        )}
        {data.updatedValues.Group.OccupiedItems && data.updatedValues.Group.OccupiedItems.length > 0 && (
          <div>
            <span>Appliquer la modification suivante aux chambres : </span>
            <br />
            <span style={{ color: 'blue' }}>
              Dates du{' '}
              {shortDateToLocaleString(data?.args?.event?.Start, userCTX.authenticationInfos.user?.Culture ?? 'fr')} au{' '}
              {shortDateToLocaleString(data?.args?.event?.End, userCTX.authenticationInfos.user?.Culture ?? 'fr')} =
              {'>'} Du{' '}
              {shortDateToLocaleString(data?.args?.event?.start, userCTX.authenticationInfos.user?.Culture ?? 'fr')} au{' '}
              {shortDateToLocaleString(data?.args?.event?.end, userCTX.authenticationInfos.user?.Culture ?? 'fr')}
            </span>
            <div
              style={{
                alignContent: 'center',
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 'bold',
                padding: '10px',
              }}
            >
              <span>Impossible chambre(s) occupée(s)</span>
              <ul>
                {' '}
                {data.updatedValues.Group.OccupiedItems.map((e: string) => (
                  <span>
                    <li>{e}</li>
                  </span>
                ))}
              </ul>
            </div>
          </div>
        )}
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'space-around',
            display: 'flex',
            padding: '10px',
            width: '100%',
          }}
        >
          <Button
            disabled={
              data.updatedValues.Group.OccupiedItems.length > 0 && (selectedLine?.length === 0 || selectedLine === null)
            }
            style={{ width: '35%', backgroundColor: colors.colorSuccessMain, border: colors.colorSuccessDark }}
            icon="fa-solid fa-check"
            onClick={() => {
              if (selectedLine) {
                const confirmGroupMove = selectedLine.map((line) => {
                  return {
                    ...line,
                    DateStart: moment(data?.args?.event?.start).format('YYYY-MM-DD'),
                    DateEnd: moment(data?.args?.event?.end).format('YYYY-MM-DD'),
                  };
                }) as BookingMove[];
                if (confirmGroupMove) {
                  bookingCTX.confirmGroupMove(confirmGroupMove).then((result) => {
                    if (result) {
                      if (filtersCTX.filters.planningFilters.CurrentSort === 1) {
                        planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                      }
                      actions.onConfirm(result);
                    } else {
                      planningCTX.getPlanning(filtersCTX.filters.planningFilters);
                    }
                  });
                }
              }
            }}
          ></Button>
          <Button
            style={{ width: '35%', backgroundColor: colors.colorErrorMain, border: colors.colorErrorDark }}
            icon="fa-solid fa-reply-all"
            onClick={() => {
              actions.onCancel();
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default GroupModale;
