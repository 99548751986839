import React, { useEffect } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import HeaderMail from './HeaderMail';
import LeftColumnMail from './LeftColumn';
import MailList from './MailList';
import MailPreview from './MailPreview';
import { useMail } from '@app/modules/Mail/MailContext';
import { useUser } from '@app/modules/user/UserContext';
import NewMail from './NewMail';
import NewDailyMail from './NewDailyMail';

const Mail = () => {
  const mailCTX = useMail();
  const userCTX = useUser();

  useEffect(() => {
    return () => {
      mailCTX.setCurrentBox('1');
      mailCTX.setCreationMode(false);
      mailCTX.setSelectedMail(null);
      mailCTX.setCreationDailyMode(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userCTX.authenticationInfos.selectedHotel) {
      mailCTX.setMailFilters({
        ...mailCTX.mailFilters,
        IdHotel: userCTX.authenticationInfos.selectedHotel?.IdHotel as number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX.authenticationInfos.selectedHotel]);
  useEffect(() => {
    switch (mailCTX.currentBox) {
      case '0':
        mailCTX.getDailyMessage(mailCTX.mailFilters);
        mailCTX.countDailyMessages();
        break;
      case '1':
        mailCTX.getInboxMessage(mailCTX.mailFilters);
        mailCTX.countInboxMessages();
        mailCTX.countInboxMessagesUnread();
        break;
      case '2':
        mailCTX.getOutboxMessage(mailCTX.mailFilters);
        mailCTX.countOutboxMessages();
        break;
      case '1-1':
        mailCTX.getTrashInboxMessage(mailCTX.mailFilters);
        mailCTX.countTrashInboxMessages();
        break;
      case '2-1':
        mailCTX.getTrashOutboxMessage(mailCTX.mailFilters);
        mailCTX.countTrashOutboxMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailCTX.mailFilters, mailCTX.currentBox]);
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 65px)' }}>
      <HeaderMail></HeaderMail>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'calc(100vh - 115px)' }}>
        <LeftColumnMail></LeftColumnMail>
        <MailList></MailList>
        {mailCTX.SelectedMail && !mailCTX.isCreationMode && <MailPreview></MailPreview>}
        {mailCTX.isCreationMode && <NewMail></NewMail>}
        {mailCTX.isCreationDailyMode && <NewDailyMail></NewDailyMail>}
      </div>
    </div>
  );
};

export default Mail;
