import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { config } from '@app/utils/config';
import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

import { useUser } from '../user/UserContext';
import { SocketMessage } from '@app/types/types';
import { decodeNotificationIdentifier } from '@app/types/dataChangeNotification';
import moment from 'moment-timezone';
import BaseForm from '@app/components/Common/popup/BaseForm';
import { useMail } from '../Mail/MailContext';
import { toast } from 'react-toastify';

// Define the type for your params
export type MessageWindowType = {
  listOfMessageWindowDisplayed: {
    id: string;
    actions: any;
    component: React.ComponentType<any>;
    x?: number | string;
    y?: number | string;
    data: any;
    translatedTitle: string;
  }[];
};
// Define the interface for ParamsContextState
interface WsContextState {
  isConnected: boolean;
  isInHotel: boolean;
  connectHotel: (IdHotel: number) => Promise<string | undefined>;
  Send: (message: SocketMessage) => void;

  getConnectedUser: () => void;
  connectedUser: any;
  setConnectedUser: React.Dispatch<React.SetStateAction<any>>;
  messageWindow: MessageWindowType;
  messages: { [sender: string]: SocketMessage[] } | null;
  updateMessages: (newMessage: SocketMessage) => void;
  updateMessagesSended: (newMessage: SocketMessage) => void;

  setMessageWindow: React.Dispatch<React.SetStateAction<MessageWindowType>>;
  hideMessageWindow: (id: string) => void; // Add this
}

// Create a context to hold your params with default values
export const WsContext = createContext<WsContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type WsProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const WsProvider: React.FC<WsProviderProps> = (props) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isInHotel, setIsInHotel] = useState<boolean>(false);
  const [connectedUser, setConnectedUser] = useState(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null); // Ref pour gérer l'intervalle de ping

  const socketRef = useRef<WebSocket | null>(null);
  const userCTX = useUser();
  const mailCtx = useMail();
  const [messages, setMessages] = useState<{ [sender: string]: SocketMessage[] }>({});
  const [messageWindow, setMessageWindow] = useState<MessageWindowType>({ listOfMessageWindowDisplayed: [] });

  const hideMessageWindow = (id: string) => {
    setMessageWindow((prevWindow) => ({
      ...prevWindow,
      listOfMessageWindowDisplayed: prevWindow.listOfMessageWindowDisplayed.filter((form) => form.id !== id),
    }));
  };

  const updateMessages = (newMessage: SocketMessage) => {
    newMessage.Date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.SenderUserId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender]) {
        updatedMessages[sender] = [];
      }
      updatedMessages[sender].push(newMessage.Message);
      return updatedMessages;
    });
  };

  const updateMessagesSended = (newMessage: SocketMessage) => {
    newMessage.Date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.RecipientUserId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender as string]) {
        updatedMessages[sender as string] = [];
      }
      updatedMessages[sender as string].push(newMessage);
      return updatedMessages;
    });
  };

  const Send = async (message: SocketMessage) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const urlToPass = 'Send';
      const response = await axios.post('/Socket/' + urlToPass, message);
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      const data: MedialogResponse = await response.data;
      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const connectHotel = async (IdHotel: number) => {
    try {
      const params = {
        IdHotel: IdHotel.toString(),
        IdUser: userCTX.authenticationInfos.user?.Id,
        IdConnection: userCTX.authenticationInfos.connectionID,
      };

      const response = await axios.post('/Socket/AddUserToHotel', params);
      if (response.status !== 200) {
        setIsInHotel(false);
        setIsConnected(false);
      } else {
        setIsInHotel(true);
      }

      const data: string = (await response.data) as string;

      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  useEffect(() => {
    const connect = () => {
      const ws = new WebSocket(
        config.WS_URL +
          userCTX.authenticationInfos.user?.Id +
          '&connectionId=' +
          userCTX.authenticationInfos.connectionID +
          '&isTech=' +
          userCTX.authenticationInfos.user?.IsAdmin,
      );
      ws.onopen = () => {
        setIsConnected(true);
        pingIntervalRef.current = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            const pingMessage: SocketMessage = {
              SenderUserId: userCTX.authenticationInfos.user?.Id || '',
              Message: 'ping',
              MessageType: 2,
              Date: moment(Date.now()).format('YYYY-MM-DD'),
            };
            ws.send(JSON.stringify(pingMessage));
          }
        }, 5000);
        setInterval(() => {
          getConnectedUser();
        }, 60000);
      };
      ws.onclose = async () => {
        setIsConnected(false);
        setIsInHotel(false);
        setTimeout(connect, 1000); // Reconnect after 1 second
        if (pingIntervalRef.current) {
          clearInterval(pingIntervalRef.current);
        }
      };
      ws.onmessage = (event) => {
        const socketMessage: SocketMessage = JSON.parse(event.data);
        switch (socketMessage.MessageType) {
          case 0: //PMS Message
            const actionToDo = decodeNotificationIdentifier(socketMessage.Message);
            actionToDo.forEach((action) => {
              switch (action) {
                case 'None':
                  // Action pour None
                  break;
                case 'InternalMessage':
                  mailCtx.countInboxMessagesUnread();

                  if (window.location.pathname.indexOf('mail') >= 0 && mailCtx.currentBox === '1') {
                    mailCtx.getInboxMessage(mailCtx.mailFilters);
                  }
                  toast.success('Vous avez reçu un nouveau message !');
                  break;
                case 'Parametres':
                  // Action pour Parametres
                  break;
                case 'AllotmentContracts':
                  // Action pour AllotmentContracts
                  break;
                case 'AllotmentDetails':
                  // Action pour AllotmentDetails
                  break;
                case 'Rooms':
                  // Action pour Rooms
                  break;
                case 'HostBookingsSubscriptions':
                  // Action pour HostBookingsSubscriptions
                  break;
                case 'CodesClient':
                  // Action pour CodesClient
                  break;
                case 'Colors':
                  // Action pour Colors
                  break;
                case 'Currencies':
                  // Action pour Currencies
                  break;
                case 'ExpenseCategory':
                  // Action pour ExpenseCategory
                  break;
                case 'DisplayColors':
                  // Action pour DisplayColors
                  break;
                case 'RoomStates':
                  // Action pour RoomStates
                  break;
                case 'Origines':
                  // Action pour Origines
                  break;
                case 'BillingInstructions':
                  // Action pour BillingInstructions
                  break;
                case 'Bookings':
                  alert('demande de booking');
                  break;
                case 'Segments':
                  // Action pour Segments
                  break;
                case 'Rates':
                  // Action pour Rates
                  break;
                case 'RoomTypes':
                  // Action pour RoomTypes
                  break;
                case 'Titres':
                  // Action pour Titres
                  break;
                case 'Users':
                  // Action pour Users
                  break;
                case 'Products':
                  // Action pour Products
                  break;
                case 'Zones':
                  // Action pour Zones
                  break;
                case 'PaymentModes':
                  // Action pour PaymentModes
                  break;
                case 'eStock':
                  // Action pour eStock
                  break;
                case 'Countries':
                  // Action pour Countries
                  break;
                case 'Familles':
                  // Action pour Familles
                  break;
                case 'TaxRates':
                  // Action pour TaxRates
                  break;
                case 'HostsInstalled':
                  // Action pour HostsInstalled
                  break;
                case 'RoomTypeCategories':
                  // Action pour RoomTypeCategories
                  break;
                case 'DailyMessages':
                  // Action pour DailyMessages
                  break;
                case 'BookingDeposits':
                  // Action pour BookingDeposits
                  break;
                case 'Invoice':
                  // Action pour Invoice
                  break;
                case 'SubFamilles':
                  // Action pour SubFamilles
                  break;
                case 'InvoicingHosts':
                  // Action pour InvoicingHosts
                  break;
                case 'Payment':
                  // Action pour Payment
                  break;
                case 'WorkStation':
                  // Action pour WorkStation
                  break;
                case 'NomTarifs':
                  // Action pour NomTarifs
                  break;
                case 'WebDispoHotels':
                  // Action pour WebDispoHotels
                  break;
                case 'Opentele':
                  // Action pour Opentele
                  break;
                case 'ServerState':
                  // Action pour ServerState
                  break;
                case 'HostName':
                  // Action pour HostName
                  break;
                case 'Letters':
                  // Action pour Letters
                  break;
                case 'ClosureRunning':
                  // Action pour ClosureRunning
                  break;
                case 'UsersRole':
                  // Action pour UsersRole
                  break;
                case 'SaleCondition':
                  // Action pour SaleCondition
                  break;
                case 'ClosureEnd':
                  // Action pour ClosureEnd
                  break;
                case 'InvoiceProduits':
                  // Action pour InvoiceProduits
                  break;
                case 'ClosedPeriod':
                  // Action pour ClosedPeriod
                  break;
                case 'JobScheduling':
                  // Action pour JobScheduling
                  break;
                case 'HotelProperties':
                  // Action pour HotelProperties
                  break;
                case 'HotelPictures':
                  // Action pour HotelPictures
                  break;
                case 'KardexAccountType':
                  // Action pour KardexAccountType
                  break;
                case 'Product_Prices':
                  // Action pour Product_Prices
                  break;
                case 'RateCancelCondition':
                  // Action pour RateCancelCondition
                  break;
                case 'PreAuthorizationsCaptured':
                  // Action pour PreAuthorizationsCaptured
                  break;
                case 'EpaymentTpe':
                  // Action pour EpaymentTpe
                  break;
                default:
                  // Optionnel: gérer les cas non reconnus
                  break;
              }
            });
            break;
          case 1: // User Message
            break;
          case 2:
            updateMessages(JSON.parse(event.data));

            break;
          default:
            break;
        }
      };
      socketRef.current = ws;
    };

    if (userCTX.authenticationInfos.connectionID && userCTX.authenticationInfos.user?.Id) {
      connect();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id]);

  useEffect(() => {
    if (isConnected && userCTX.authenticationInfos.selectedHotel) {
      connectHotel(userCTX.authenticationInfos.selectedHotel.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, userCTX.authenticationInfos.selectedHotel]);

  const getConnectedUser = async () => {
    const response = await axios.get('/Socket/GetConnectedUser', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data = await response.data;
    setConnectedUser(data);
    return data;
  };

  return (
    <WsContext.Provider
      value={{
        isConnected,
        connectHotel,
        isInHotel,
        Send,
        connectedUser,
        getConnectedUser,
        setConnectedUser,

        messages,
        updateMessages,
        messageWindow,
        hideMessageWindow,
        setMessageWindow,
        updateMessagesSended,
      }}
    >
      {' '}
      {messageWindow.listOfMessageWindowDisplayed.map((form, index) => {
        return (
          <BaseForm
            id={form.id}
            data={form.data}
            translatedTitle={form.translatedTitle ?? ''}
            key={index}
            component={form.component}
            actions={form.actions}
            draggable={false}
            fullWidth={false}
            fullHeight={false}
            x={form.x}
            y={form.y}
            zIndex={999}
          />
        );
      })}
      {props.children}
    </WsContext.Provider>
  );
};

// Create a custom hook to access the params
export function useWs(): WsContextState {
  const wsContext = useContext(WsContext);
  if (wsContext === undefined) {
    throw new Error('useWs must be used within a WsProvider');
  }
  return wsContext;
}
