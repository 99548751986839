import React, { useEffect, useState } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { useMail } from '@app/modules/Mail/MailContext';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { isMobile } from 'react-device-detect';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importer le style de base
import colors from '@app/styles/colors';
import { getDateFormat } from '@app/utils/dates';
import { useUser } from '@app/modules/user/UserContext';
import UsersList from './UsersList';
import { useSubForm } from '@app/modules/SubFormContext';
import { Chip } from '@mui/material';
import { EmailToSend } from '@app/types/types';
import moment from 'moment-timezone';

const NewMail = () => {
  const mailCTX = useMail();

  const userCTX = useUser();
  const [content, setContent] = useState('');
  const [subject, setSubjet] = useState(mailCTX.SelectedMail?.Subject ? 'Re : ' + mailCTX.SelectedMail.Subject : '');
  const subFormCTX = useSubForm();
  const handleChange = (value: string) => {
    setContent(value);
  };
  const sendMessage = () => {
    let mail: EmailToSend = {
      Body: content,
      DateValidityFrom: moment(mailCTX.activeDate).format('YYYY-MM-DD'),
      Subject: subject,

      IdHotelFrom:
        (userCTX.authenticationInfos.selectedHotel?.IdHotel as number) ?? userCTX.authenticationInfos.user?.OwningHotel,
      IdUserFrom: userCTX.authenticationInfos.user?.Id.toString() as string,
      Recipients: mailCTX?.userToSend?.map((recipient) => {
        const user = mailCTX.recipients
          ?.flatMap((hotel) => hotel.Users)
          .find((u) => u.Id === recipient.IdUser && u.IdHotel === recipient.IdHotel);
        return { IdUser: user?.Id as string, IdHotel: user?.IdHotel as number };
      }),
    };
    mailCTX.send(mail);
    mailCTX.setUserToSend(null);
    mailCTX.setCreationMode(false);
  };
  useEffect(() => {
    if (mailCTX && mailCTX.recipients === null) {
      mailCTX.getRecipients();
    }
  }, [mailCTX.recipients]);
  useEffect(() => {
    if (mailCTX.SelectedMail) {
      setSubjet('Re : ' + mailCTX.SelectedMail.Subject);
      setContent(
        '<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------<br/>' +
          mailCTX.SelectedMail.Body,
      );
    }
  }, [mailCTX.SelectedMail]);
  return (
    <div
      style={{
        height: 'calc(100%)',
        width: 'calc(100% - 650px)',
        backgroundColor: '#f6f6f6',
        borderLeft: '1px solid #dfdfdf',
      }}
    >
      <div
        className="small-box-mail bg-gradient-light"
        style={{
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#fff',
          margin: '10px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ width: '50px' }}>
            <Button
              style={{ color: colors.colorPrimaryMain, backgroundColor: 'white', height: '34px', marginRight: '10px' }}
              id="selectRecipient"
              onClick={() => {
                const id = 7000;
                const newSubForm = {
                  id: id, // ou utilisez un ID unique
                  component: UsersList, // Remplacez par le composant que vous souhaitez afficher
                  data: {},
                  actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
                  draggable: true, // Définissez-le en fonction de vos besoins
                  fullWidth: false, // Définissez-le en fonction de vos besoins
                  fullHeight: false, // Définissez-le en fonction de vos besoins
                  height: '600px',
                  x: 'calc((100vw - 300px)/2)',
                  y: '100px',
                  translatedTitle: 'Destinataire(s)',
                  showBlackdiv: true,
                };

                // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
                subFormCTX.setSubForm((prevSubForm) => ({
                  ...prevSubForm,
                  listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
                }));
              }}
            >
              A
            </Button>
          </span>
          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            {mailCTX?.userToSend?.map((recipient) => {
              const user = mailCTX.recipients
                ?.flatMap((hotel) => hotel.Users)
                .find((u) => u.Id === recipient.IdUser && u.IdHotel === recipient.IdHotel);
              return user ? (
                <Chip
                  key={recipient?.IdHotel + recipient?.IdUser}
                  label={user.DisplayName}
                  style={{ margin: '0.25rem' }}
                />
              ) : (
                <Chip
                  key={recipient.IdHotel + recipient.IdUser}
                  label="Nom non trouvé"
                  style={{ margin: '0.25rem', backgroundColor: '#f8d7da', color: '#721c24' }}
                />
              );
            })}
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ width: '50px' }}>Objet : </span>
          <div
            style={{
              paddingTop: '10px',
              width: 'calc(100% - 50px)',
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputText
              id="subject"
              value={subject}
              onChange={(e) => setSubjet(e.target.value)}
              style={{ width: 'calc(100% - 230px)' }}
            />
            <span style={{ width: '110px' }}>Actif à partir du</span>
            <Calendar
              inputStyle={{ height: '34px', width: '100px' }}
              showWeek
              style={{ height: '34px', lineHeight: '34px' }}
              value={mailCTX.activeDate}
              dateFormat={getDateFormat(mailCTX.activeDate, userCTX.authenticationInfos.language ?? 'fr')}
              onChange={(e) => mailCTX.setActiveDate(e.value as Date)}
              todayButtonClassName={'hidden'}
              touchUI={isMobile}
              locale={userCTX.authenticationInfos?.language || 'fr'}
            ></Calendar>
          </div>
        </div>
      </div>

      <div
        className="small-box-mail bg-gradient-light"
        style={{
          height: 'calc(100% - 140px)',
          maxHeight: 'calc(100% - 140px)',
          overflow: 'auto',
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: 'red',
          margin: '10px',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ReactQuill
          id="contentMail"
          value={content}
          onChange={handleChange}
          style={{
            backgroundColor: 'white',
            minWidth: '100%',
            minHeight: 'calc(100% - 100px)',
            maxHeight: 'calc(100% - 100px)',
          }}
          theme="snow"
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline'],
              ['link', 'image'],
              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }],
              [{ align: [] }],
            ],
          }}
          placeholder="Commencez à écrire votre template d'email ici..."
        />
        <Button
          disabled={subject === '' || content === ''}
          onClick={() => sendMessage()}
          style={{ position: 'fixed', bottom: 35, right: 20 }}
          id="sendMail"
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default NewMail;
