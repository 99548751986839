import React from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { useMail } from '@app/modules/Mail/MailContext';
import { formatFullDate, shortDateAndTimeToLocaleString } from '@app/utils/dates';
import { useUser } from '@app/modules/user/UserContext';
import moment from 'moment-timezone';

const MailPreview = () => {
  const mailCTX = useMail();
  const userCTX = useUser();
  return (
    <div
      style={{
        height: 'calc(100%)',
        width: 'calc(100% - 650px)',
        backgroundColor: '#f6f6f6',
        borderLeft: '1px solid #dfdfdf',
      }}
    >
      <div
        className="small-box-mail bg-gradient-light"
        style={{
          height: '50px',
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#fff',
          margin: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h5>
          {' '}
          {mailCTX.SelectedMail && mailCTX.SelectedMail.Subject}{' '}
          {
            <>
              <i style={{ margin: '10px' }} className="fas fa-eye" aria-hidden="true"></i>
              {shortDateAndTimeToLocaleString(
                moment(mailCTX.SelectedMail?.DateRead).toDate(),
                userCTX.authenticationInfos.user?.Culture as string,
              )}
            </>
          }
        </h5>
      </div>
      <div
        className="small-box-mail bg-gradient-light"
        style={{
          height: 'calc(100% - 70px)',
          maxHeight: 'calc(100% - 70px)',
          overflow: 'auto',
          width: 'calc(100% - 20px)',
          border: '1px solid #dfdfdf',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#fff',
          margin: '10px',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
        id="mailContent"
        dangerouslySetInnerHTML={{ __html: mailCTX.SelectedMail?.Body as string }}
      ></div>
    </div>
  );
};

export default MailPreview;
