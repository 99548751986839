import { useEffect } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useMainCourante } from '@app/modules/MainCourante/MainCouranteContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { formatPrice } from '@app/utils/formatCurrency';
import { indexOf } from 'lodash';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import colors from '@styles/colors';
import ExportExcel from '@app/components/Common/export/ExportExcel';
import moment from 'moment-timezone';

// Define a type for your data

const MainCouranteDataTable: React.FC = () => {
  const mainCouranteCTX = useMainCourante();
  const gridData = mainCouranteCTX.MainCouranteMonthData;
  const gridDetail = mainCouranteCTX.MainCouranteMonthDataByDay;
  const userCTX = useUser();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const filtersCTX = useFilters();
  const mainCouranteFilters = filtersCTX.filters.mainCouranteFilters;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const [t] = useTranslation();
  useEffect(() => {
    if (filtersCTX.filters.mainCouranteFilters.needReload === true && isTech !== undefined) {
      mainCouranteCTX.setMainCouranteMonthDataByDay(null);
      mainCouranteCTX.getMainCouranteMonthData({
        mainCouranteFilters,
        params: {
          isTech,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
      });
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        mainCouranteFilters: {
          ...prevState.mainCouranteFilters,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filtersCTX,
    filtersCTX.filters.mainCouranteFilters.needReload,
    isTech,
    mainCouranteCTX,
    mainCouranteFilters,
    selectedHotel,
  ]);

  const formatBody = (data: rowData, i: ColumnBodyOptions) => {
    const key = i.field as keyof rowData; // Cast to the correct key type
    const key2 = i.field as keyof cellData; // Cast to the correct key type

    const cellValue = data.Data.find((item) => item.KeyIterator === parseInt(key))?.Value.toString();

    if (cellValue !== undefined) {
      return (
        <div
          style={{
            paddingRight: '5px',
            fontWeight: gridData?.Columns[parseInt(key2)].Foreground !== undefined ? 'bold' : 'normal',
            color:
              gridData?.Columns[parseInt(key2)].Foreground !== undefined
                ? gridData?.Columns[parseInt(key2)].KeyIterator % 2 === 0
                  ? colors.colorPrimaryLight
                  : 'white'
                : '',
            backgroundColor:
              gridData?.Columns[parseInt(key2)].Background !== undefined
                ? gridData?.Columns[parseInt(key2)].KeyIterator % 2 === 0
                  ? '#383838'
                  : '#343a40'
                : '',
          }}
        >
          {formatPrice(parseFloat(cellValue), null)}
        </div>
      );
    }
  };

  const formatDetailBody = (data: rowData, i: ColumnBodyOptions) => {
    const key = i.field as keyof rowData; // Cast to the correct key type
    const key2 = i.field as keyof cellData; // Cast to the correct key type

    const cellValue = data.Data.find((item) => item.KeyIterator === parseInt(key))?.Value.toString();

    if (cellValue !== undefined) {
      return (
        <div
          style={{
            paddingRight: '5px',

            fontWeight: gridDetail?.Columns[parseInt(key2)].Foreground !== undefined ? 'bold' : 'normal',
            color:
              gridDetail?.Columns[parseInt(key2)].Foreground !== undefined
                ? gridDetail?.Columns[parseInt(key2)].KeyIterator % 2 === 0
                  ? colors.colorPrimaryLight
                  : 'white'
                : '',
            backgroundColor:
              gridDetail?.Columns[parseInt(key2)].Background !== undefined
                ? gridDetail?.Columns[parseInt(key2)].KeyIterator % 2 === 0
                  ? '#383838'
                  : '#343a40'
                : '',
          }}
        >
          {formatPrice(parseFloat(cellValue), null)}
        </div>
      );
    }
  };

  const formatDate = (data: rowData) => {
    let formattedString;
    if (data.DataType === 18) {
      formattedString = t('maincourante.label.' + data.Name);
    } else {
      formattedString = new Date(data.Name).toLocaleDateString(authData.user?.Culture, {
        month: 'long', // Full month name
        year: 'numeric', // Day of the month
      });
    }
    return (
      <div
        style={{
          textAlign: 'right',
          fontSize: '9pt',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          textTransform: 'capitalize',
          marginRight: '10px',
        }}
      >
        {data.Link ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a
              onClick={() => {
                mainCouranteCTX.getFileFromUrl(data.Link);
              }}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            >
              <i className="pi pi-file-pdf"></i>
            </a>
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                mainCouranteCTX.getMainCouranteDataPerDay({
                  mainCouranteFilters,
                  monthToDisplay: new Date(data.Name),
                  params: {
                    isTech,
                    selectedHotel: userCTX.authenticationInfos.selectedHotel,
                  },
                });
              }}
            >
              {formattedString}
            </span>
          </div>
        ) : data.DataType === 18 ? (
          <>{formattedString}</>
        ) : (
          <a
            className="clickableDate"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              mainCouranteCTX.getMainCouranteDataPerDay({
                mainCouranteFilters,
                monthToDisplay: new Date(data.Name),
                params: {
                  isTech,
                  selectedHotel: userCTX.authenticationInfos.selectedHotel,
                },
              });
            }}
          >
            {formattedString}
          </a>
        )}
      </div>
    );
  };

  const formatDayDate = (data: rowData) => {
    const formattedDate = new Date(data.Name).toLocaleDateString(authData.user?.Culture, {
      day: '2-digit',
      month: '2-digit', // Full month name
      year: 'numeric', // Day of the month
    });

    return (
      <div
        style={{
          textAlign: 'right',
          fontSize: '9pt',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          textTransform: 'capitalize',
          marginRight: '10px',
        }}
      >
        {data.Link ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <a
              onClick={() => {
                mainCouranteCTX.getFileFromUrl(data.Link);
              }}
              style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px' }}
            >
              <i className="pi pi-file-pdf"></i>
            </a>
            <span>
              {new Date(data.Name).toLocaleString(userCTX.authenticationInfos.language, { weekday: 'short' })[0] +
                new Date(data.Name).toLocaleString(userCTX.authenticationInfos.language, { weekday: 'short' })[1] +
                ' ' +
                formattedDate}
            </span>
          </div>
        ) : (
          <>
            {new Date(data.Name).toLocaleString(userCTX.authenticationInfos.language, { weekday: 'short' })[0] +
              new Date(data.Name).toLocaleString(userCTX.authenticationInfos.language, { weekday: 'short' })[1] +
              ' ' +
              formattedDate}
          </>
        )}
      </div>
    );
  };

  const formatHeader = (data: cellData) => {
    // gridData.Data.Columns.find((t) => t.KeyIterator === e.KeyIterator)?.Value;
    if (indexOf(data.Value.toString(), '~') === 0) {
      return <>{t('maincourante.label.' + data.Value.toString())}</>;
    } else {
      return <div style={{ textAlign: 'center', justifyContent: 'center' }}>{data.Value}</div>;
    }
  };

  return (
    <>
      {gridData && (
        <>
          <div className={`small-box bg-gradient-light`}>
            <div className="small-box" style={{ borderRadius: '8px' }}>
              <DataTable className="normalDT maincouranteDt" value={gridData?.Data} stripedRows scrollable rowHover>
                <Column
                  className="previsionDTName"
                  style={{
                    padding: '2px',
                    width: '1%',
                    backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                    color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
                  }}
                  header={
                    <ExportExcel
                      filename="MC"
                      datestart={moment(filtersCTX.filters.mainCouranteFilters.DateStart).format('YYYY-MM-DD')}
                      dateend={moment(filtersCTX.filters.mainCouranteFilters.DateEnd).format('YYYY-MM-DD')}
                      data={gridData.Data.map((monthData) => {
                        const combined: { [key: string]: number | string } = { Name: monthData.Name };

                        monthData.Data.forEach((item) => {
                          const headerItem = gridData?.Columns?.map((e) => {
                            return {
                              field: e.KeyIterator.toString(),
                              header:
                                indexOf(e.Value.toString(), '~') === 0
                                  ? t('maincourante.label.' + e.Value.toString())
                                  : e.Value,
                            };
                          }).find((header) => header.field === item.KeyIterator.toString());
                          if (headerItem) {
                            combined[headerItem.header] = item.Value;
                          }
                        });

                        return combined;
                      })}
                      exportedColumns={[{ field: 'Name', header: 'Période' }].concat(
                        gridData?.Columns?.map((e) => {
                          return {
                            field: e.KeyIterator.toString(),
                            header:
                              indexOf(e.Value.toString(), '~') === 0
                                ? t('maincourante.label.' + e.Value.toString())
                                : e.Value,
                          };
                        }),
                      )}
                    />
                  }
                ></Column>
                <Column
                  key={'KeyIterator'}
                  field={'Name'}
                  header={<>{t('maincourante.label.Period')}</>}
                  frozen
                  className={'cell-to-hide titleTr'}
                  style={{
                    minWidth: '150px',
                    maxWidth: '150px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    display: 'table-cell',
                    textAlign: 'right',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                    color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
                  }}
                  headerStyle={{
                    textAlign: 'center',
                    fontSize: '9pt',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textTransform: 'uppercase',
                  }}
                  body={formatDate}
                ></Column>
                {gridData?.Columns?.map((e) => {
                  return (
                    <Column
                      key={'KeyIterator'}
                      field={e.KeyIterator.toString()}
                      headerStyle={{
                        textAlign: 'center',
                        fontSize: '9pt',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        backgroundColor:
                          e.Background !== undefined ? (e.KeyIterator % 2 === 0 ? '#383838' : '#343a40') : '',
                        color:
                          e.Foreground !== undefined
                            ? e.KeyIterator % 2 === 0
                              ? colors.colorPrimaryLight
                              : 'white'
                            : '',
                      }}
                      header={formatHeader(e)}
                      style={{
                        minWidth: '150px',
                        maxWidth: '150px',
                        alignContent: 'center',
                        textAlign: 'right',
                        overflow: 'hidden',
                      }}
                      body={formatBody}
                    ></Column>
                  );
                })}
              </DataTable>
            </div>
          </div>
          <div>
            {gridDetail !== null && (
              <div className={`small-box bg-gradient-light`}>
                <div className="small-box" style={{ borderRadius: '8px' }}>
                  <div>
                    <DataTable
                      id={'DetailDT'}
                      className="normalDT"
                      value={gridDetail?.Data}
                      showGridlines
                      showHeaders={true}
                      scrollable
                    >
                      <Column
                        className="previsionDTName"
                        style={{
                          padding: '2px',
                          width: '1%',
                          backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                          color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
                        }}
                        header={
                          <ExportExcel
                            filename="MC_Detail"
                            datestart={moment(gridDetail.DateStart).format('YYYY-MM-DD')}
                            dateend={moment(gridDetail.DateEnd).format('YYYY-MM-DD')}
                            data={gridDetail.Data.map((dayData) => {
                              const combined: { [key: string]: number | string } = { Name: dayData.Name };

                              dayData.Data.forEach((item) => {
                                const headerItem = gridDetail?.Columns?.map((e) => {
                                  return {
                                    field: e.KeyIterator.toString(),
                                    header:
                                      indexOf(e.Value.toString(), '~') === 0
                                        ? t('maincourante.label.' + e.Value.toString())
                                        : e.Value,
                                  };
                                }).find((header) => header.field === item.KeyIterator.toString());
                                if (headerItem) {
                                  combined[headerItem.header] = item.Value;
                                }
                              });

                              return combined;
                            })}
                            exportedColumns={[{ field: 'Name', header: 'Date' }].concat(
                              gridDetail?.Columns?.map((e) => {
                                return {
                                  field: e.KeyIterator.toString(),
                                  header:
                                    indexOf(e.Value.toString(), '~') === 0
                                      ? t('maincourante.label.' + e.Value.toString())
                                      : e.Value,
                                };
                              }),
                            )}
                          />
                        }
                      ></Column>
                      <Column
                        key={`KeyIterator`}
                        field={'Name'}
                        header={'Date'}
                        frozen
                        className={'cell-to-hide titleTr'}
                        style={{
                          minWidth: '150px',
                          maxWidth: '150px',
                          whiteSpace: 'nowrap',
                          fontWeight: 'bold',
                          display: 'table-cell',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',

                          backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                          color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
                        }}
                        body={formatDayDate}
                      ></Column>

                      {gridDetail?.Columns?.map((e: cellData) => {
                        return (
                          <Column
                            key={e.KeyIterator}
                            header={formatHeader(e)}
                            headerStyle={{
                              textAlign: 'left',
                              fontSize: '9pt',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              justifyContent: 'left',
                            }}
                            style={{
                              minWidth: '150px',
                              maxWidth: '150px',
                              alignContent: 'center',
                              textAlign: 'right',

                              overflow: 'hidden',
                            }}
                            field={e.KeyIterator.toString()}
                            body={formatDetailBody}
                          ></Column>
                        );
                      })}
                    </DataTable>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MainCouranteDataTable;
