import React from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import PickupSelector from '@app/components/Common/popup/pickupSelector';
import Kardex from '@app/components/Desktop/Kardex/Kardex';
import KardexGrid from '@app/components/Desktop/Kardex/KardexGrid';
import KardexGridMobile from '@app/components/Mobile/Kardex/KardexGridMobile';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useTabView } from '@app/modules/Tabview/TabViewContext';
import { Button } from 'primereact/button';
import { isMobileOnly } from 'react-device-detect';
import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';
import moment from 'moment-timezone';

const Dev = () => {
  const subFormCTX = useSubForm();
  const tabViewCTX = useTabView();
  const userCTX = useUser();
  const wsCTX = useWs();
  const DataFactoryCTX = useDataFactory();
  const onButtonClick = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: isMobileOnly ? KardexGridMobile : KardexGrid, // Remplacez par le composant que vous souhaitez afficher
      data: null, // Les données que vous souhaitez transmettre au composant
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Liste des kardex',
      width: '80vw',
      x: '10vw',
      y: '10vh',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const onButtonClick2 = () => {
    const id = tabViewCTX.currentID;

    const newTabView = {
      id: id, // ou utilisez un ID unique
      component: isMobileOnly ? KardexGridMobile : KardexGrid, // Remplacez par le composant que vous souhaitez afficher
      data: null, // Les données que vous souhaitez transmettre au composant
      actions: { hideTabView: () => tabViewCTX.hideTabView(id) },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Liste des kardex',
      tabMode: true,
      componentType: 'Kardex',
    };

    if (tabViewCTX.tabView.listOfTabsDisplayed.length === 0) {
      tabViewCTX.setTabView((prevSubForm) => ({
        ...prevSubForm,
        listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
      }));
      tabViewCTX.minimizeTabView(false);
      tabViewCTX.setCurrentID(id + 1);
      tabViewCTX.setActiveTab(id);
    } else {
      tabViewCTX.minimizeTabView(false);
      tabViewCTX.setActiveTab(0);
    }
  };

  const onButtonKardexClick = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: Kardex, // Remplacez par le composant que vous souhaitez afficher
      data: { KardexType: 'User' }, // Les données que vous souhaitez transmettre au composant
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Fiche kardex',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const onButtonPickupSelectorClick = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: PickupSelector, // Remplacez par le composant que vous souhaitez afficher
      data: { resources: DataFactoryCTX.DataDictionnary.Segments },
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '300px',
      height: '500px',
      x: 'calc((100vw - 300px)/2)',
      y: '100px',
      translatedTitle: 'Segments',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const onButtonPickupSelectorClick2 = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: PickupSelector, // Remplacez par le composant que vous souhaitez afficher
      data: { resources: DataFactoryCTX.DataDictionnary.Origines },
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '300px',
      height: '500px',
      x: 'calc((100vw - 300px)/2)',
      y: '100px',
      translatedTitle: 'Origines',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  return (
    <div>
      <ContentHeader title="Dev Page" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <Button label="Liste des kardex" onClick={onButtonClick}></Button>
            <br />
            <Button label="Liste des kardex en vue tab" onClick={onButtonClick2}></Button>

            <br />
            <Button label="Fiche kardex" onClick={onButtonKardexClick}></Button>
            <br />
            <Button label="pickup Selectro" onClick={onButtonPickupSelectorClick}></Button>
            <br />
            <Button label="pickup Selectro" onClick={onButtonPickupSelectorClick2}></Button>
            <br />

            <br />
            <Button
              label="Send to hotel"
              onClick={() =>
                wsCTX.Send({
                  Message: 2325184,
                  MessageType: 0,
                  RecipientHotelId: userCTX.authenticationInfos.selectedHotel?.IdHotel.toString() as string,
                  SenderUserId: userCTX.authenticationInfos.user?.Id as string,
                  Date: moment(Date.now()).format('YYYY-MM-DD'),
                })
              }
            ></Button>

            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dev;
