import React from 'react';

import { PfDropdown, PfImage } from '@profabric/react-components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMail } from '@app/modules/Mail/MailContext';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .text-sm {
    margin-bottom: 0;
  }
`;

const NotificationsDropdown = () => {
  const navigate = useNavigate();
  const mailCTX = useMail();
  return (
    <>
      <StyledDropdown hideArrow>
        <div id="enveloppeBtn" slot="button" onClick={() => navigate('/mail')}>
          <i className="far fa-envelope" />
          <span className="badge badge-success navbar-badge">
            {mailCTX.inboxMessagesUnreadCount !== 0 ? mailCTX.inboxMessagesUnreadCount : ''}
          </span>
        </div>
      </StyledDropdown>
    </>
  );
};

export default NotificationsDropdown;
