import React, { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { Button, Datepicker, MbscDatepickerChangeEvent } from '@mobiscroll/react';
import moment from 'moment-timezone';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface PointProps {
  updateParentMethod: (sort: number) => void;
  renderDate: string;
}

const Point: React.FC<PointProps> = ({ renderDate }) => {
  const planningCTX = usePlanning();
  const [t] = useTranslation();
  const events = planningCTX.PlanningPoint;
  const filtersCTX = useFilters();
  const [showDeparture, setShowDeparture] = useState(true);
  const [showArrival, setShowArrival] = useState(true);
  const [showInHouse, setShowInHouse] = useState(true);
  const [showFree, setShowFree] = useState(true);
  const [showDiry, setShowDirty] = useState(true);
  const [showClean, setShowClean] = useState(true);

  const [floors, setFloors] = useState<number[]>([]);
  const [currentDate, setCurrentDate] = React.useState(renderDate);
  const [showNeedControl, setShowNeedControl] = useState(true);
  const [showDirtyBed, setShowDiryBed] = useState(true);
  const [currentSearch, setCurrentSearch] = useState('');
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  useEffect(() => {
    filtersCTX.setFilters((prevState) => ({
      ...prevState,
      planningFilters: {
        ...prevState.planningFilters,
        DatePointStart: moment(filtersCTX.filters.planningFilters.DateStart).format('YYYYMMDD'),
        DatePointEnd: moment(filtersCTX.filters.planningFilters.DateStart).add(1, 'day').format('YYYYMMDD'),
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.planningFilters.DateStart]);

  useEffect(() => {
    if (filtersCTX.filters.planningFilters.DatePointStart) {
      planningCTX.getPlanningPoint(filtersCTX.filters.planningFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.planningFilters.DatePointStart]);

  useEffect(() => {
    if (events) {
      const temp = new Set(events.map((room) => (room.Floor as number) ?? 0));
      setFloors(Array.from(temp));
    }
  }, [events, filtersCTX.filters.planningFilters.TypeToDisplay]);

  return (
    <div style={{ fontFamily: '-apple-system,Segoe UI,Roboto,sans-serif' }}>
      <>
        <div style={{ flexDirection: 'row', display: 'flex', fontSize: '.875em', fontWeight: '600' }}>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <div
              style={{
                flexDirection: 'row',
                height: 'calc(100vh - 65px)',
                justifyContent: 'space-around',
                textAlign: 'center',
                width: '160px',
                backgroundColor: '#343a40',
                color: 'white',
                alignItems: 'flex-start',
              }}
            >
              <div className="p-input-icon-left" style={{ width: '100%', paddingTop: '10px' }}>
                <InputText
                  placeholder="Rechercher"
                  style={{ width: '90%' }}
                  value={currentSearch}
                  onChange={(e) => setCurrentSearch(e.target.value)}
                />
              </div>

              <div style={{ width: '100%', float: 'left' }}>
                <div
                  style={{
                    width: '100%',
                    height: '30px',
                    padding: '0px',
                    marginBottom: '5px',
                    backgroundColor: '#606468',
                    marginTop: '10px',
                    lineHeight: '30px',
                  }}
                >
                  {t('labels.occupancy')}
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      border: '2px solid white',
                      background: 'darkgreen',
                    }}
                  >
                    <i className="fa-solid fa-plane-arrival" style={{ color: 'white', marginTop: '7px' }}></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsArrival === true)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showArrival}
                    onChange={(e) => setShowArrival(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      background: colors.colorSecondaryMain,

                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-solid fa-plane-departure" style={{ color: 'white', marginTop: '7px' }}></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsDeparture === true)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showDeparture}
                    onChange={(e) => setShowDeparture(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      background: darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain,
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-solid fa-person-shelter" style={{ color: 'white', marginTop: '7px' }}></i>
                  </div>

                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter(
                          (x) =>
                            x.IsInHouse === true ||
                            (x.IsArrival === false &&
                              x.IsDeparture === false &&
                              x.IsInHouse === false &&
                              x.Status === 'P'),
                        )
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showInHouse}
                    onChange={(e) => setShowInHouse(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',
                      background: 'repeating-linear-gradient(-45deg, #ccc, #dfdfdf 11px, #ffefef 11px, #efefef 22px)',
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  ></div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsFree === true)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showFree}
                    onChange={(e) => setShowFree(e.value)}
                  />
                </div>

                <div
                  style={{
                    width: '100%',
                    height: '30px',
                    padding: '0px',
                    marginBottom: '5px',
                    backgroundColor: '#606468',
                    lineHeight: '30px',
                  }}
                >
                  {t('labels.propreté')}
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',

                      color: '#343a40',
                      background: '#343a40',
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-regular fa-thumbs-up" style={{ color: 'white' }}></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsDirty === 0)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showClean}
                    onChange={(e) => setShowClean(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',

                      color: '#343a40',
                      background: colors.colorErrorMain,
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-solid fa-broom"></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsDirty === 1)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showDiry}
                    onChange={(e) => setShowDirty(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',

                      color: '#343a40',
                      background: 'yellow',
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsDirty === 2)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showNeedControl}
                    onChange={(e) => setShowNeedControl(e.value)}
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '50px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '2px solid white',
                      float: 'left',
                      borderRadius: '4px',
                      textAlign: 'center',
                      verticalAlign: 'center',

                      color: '#343a40',
                      background: 'darkorange',
                      fontWeight: 'normal',
                      fontSize: '12pt',
                    }}
                  >
                    <i className="fa-solid fa-bed"></i>
                  </div>
                  <div style={{ fontSize: '16pt', padding: '10px', width: '40px' }}>
                    {
                      events
                        ?.filter((x) => x.IsDirty === 8)
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        ).length
                    }
                  </div>
                  <InputSwitch
                    style={{ float: 'left', marginLeft: '30px' }}
                    checked={showDirtyBed}
                    onChange={(e) => setShowDiryBed(e.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: 'calc(100% - 160px)',
              minHeight: '800px',
              backgroundColor: '#dfdfdf',
              flexWrap: 'wrap',
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'left',
              alignContent: 'flex-start',
              border: '2px solid #343a40',
            }}
          >
            {floors
              .sort((a, b) => a - b)
              .map((f) => (
                <div key={f} style={{ width: '100%', flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ width: '100%', backgroundColor: '#343a40' }}>
                    <div
                      style={{
                        paddingLeft: '20px',
                        color: 'white',
                        height: '35px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <i className="fa-solid fa-stairs" style={{ margin: '10px' }}></i>
                      Etage {f}
                    </div>
                  </div>
                  <div
                    style={{ width: '100%', flexDirection: 'row', display: 'flex', flexWrap: 'wrap', padding: '5px' }}
                  >
                    {events &&
                      events
                        .filter(
                          (e) =>
                            filtersCTX.filters.planningFilters.TypeToDisplay &&
                            filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
                        )
                        .filter((x) => x.Floor === f)
                        .filter((x) => {
                          if (currentSearch !== '') {
                            if (
                              x.RoomNumber?.toString().includes(currentSearch) ||
                              x.DisplayName?.toLowerCase().includes(currentSearch.toLowerCase()) ||
                              x.TypeDescription?.toLowerCase().includes(currentSearch.toLowerCase())
                            ) {
                              return x;
                            }
                          } else {
                            return x;
                          }
                        })
                        .filter((x) => (showArrival === false ? (x.IsArrival === true ? null : x) : x))
                        .filter((x) => (showDeparture === false ? (x.IsDeparture === true ? null : x) : x))
                        .filter((x) => (showFree === false ? (x.IsFree === true ? null : x) : x))
                        .filter((x) => (showDiry === false ? (x.IsDirty === 1 ? null : x) : x))
                        .filter((x) => (showNeedControl === false ? (x.IsDirty === 2 ? null : x) : x))
                        .filter((x) => (showDirtyBed === false ? (x.IsDirty === 8 ? null : x) : x))
                        .filter((x) => (showClean === false ? (x.IsDirty === 0 ? null : x) : x))

                        .filter((x) =>
                          showInHouse === false
                            ? x.IsInHouse === true ||
                              (x.IsArrival === false &&
                                x.IsDeparture === false &&
                                x.IsInHouse === false &&
                                x.Status === 'P')
                              ? null
                              : x
                            : x,
                        )

                        .map((e) => (
                          <div
                            key={e.id}
                            style={{
                              margin: '5px',
                              width: '225px',
                              height: '50px',
                              display: 'block',
                              backgroundColor: 'white',
                              borderRadius: '4px',
                              border: '2px solid #333',
                            }}
                          >
                            <div
                              style={{
                                width: '50px',
                                height: '100%',
                                float: 'left',

                                textAlign: 'center',
                                verticalAlign: 'center',
                                borderRight: '2px solid #333',
                                background: e.IsArrival
                                  ? 'darkgreen'
                                  : e.IsDeparture
                                    ? colors.colorSecondaryMain
                                    : e.IsInHouse || e.Status === 'P'
                                      ? darkMode
                                        ? colors.colorPrimaryLight
                                        : colors.colorPrimaryMain
                                      : 'repeating-linear-gradient(-45deg, #ccc, #dfdfdf 11px, #ffefef 11px, #efefef 22px)',
                                fontWeight: 'normal',
                                fontSize: '12pt',
                              }}
                            >
                              {e.IsArrival && (
                                <i
                                  className="fa-solid fa-plane-arrival fa-xl"
                                  style={{ color: 'white', marginTop: '25px' }}
                                ></i>
                              )}
                              {e.IsDeparture && (
                                <i
                                  className="fa-solid fa-plane-departure fa-xl"
                                  style={{ color: 'white', marginTop: '25px' }}
                                ></i>
                              )}
                              {(e.IsInHouse || e.Status === 'P') && !e.IsArrival && !e.IsDeparture && (
                                <i
                                  className="fa-solid fa-person-shelter fa-xl"
                                  style={{ color: 'white', marginTop: '25px' }}
                                ></i>
                              )}
                            </div>
                            <div
                              style={{
                                paddingLeft: '1px',
                                whiteSpace: 'nowrap',
                                width: 'calc(100% - 75px)',
                                float: 'left',
                                height: '25px',
                                backgroundColor: '#343a40',

                                color: 'white',
                              }}
                            >
                              <div
                                style={{
                                  width: '8px',
                                  height: '8px',
                                  borderRadius: '8px',
                                  backgroundColor: e.CouleurType as string,
                                  float: 'left',
                                  margin: '4px',
                                  marginTop: '8px',
                                }}
                              ></div>
                              <span
                                style={{
                                  float: 'left',
                                  width: 'calc(100% - 16px)',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {e.RoomNumber} {e.RoomType}
                              </span>
                            </div>
                            <div
                              style={{
                                width: '25px',
                                float: 'right',
                                height: '25px',
                                textAlign: 'center',
                                verticalAlign: 'center',

                                color: '#343a40',
                                background:
                                  e.IsDirty === 0
                                    ? '#343a40'
                                    : e.IsDirty === 1
                                      ? colors.colorErrorMain
                                      : e.IsDirty === 2
                                        ? 'yellow'
                                        : e.IsDirty === 8
                                          ? 'darkorange'
                                          : '',
                                fontWeight: 'normal',
                                fontSize: '12pt',
                              }}
                            >
                              {e.IsDirty === 0 ? (
                                ''
                              ) : e.IsDirty === 1 ? (
                                <i className="fa-solid fa-broom"></i>
                              ) : e.IsDirty === 2 ? (
                                <i className="fa-solid fa-eye"></i>
                              ) : e.IsDirty === 8 ? (
                                <i className="fa-solid fa-bed"></i>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              style={{
                                paddingLeft: '5px',
                                whiteSpace: 'nowrap',
                                float: 'left',
                                width: 'calc(100% - 50px)',

                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {e.DisplayName}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default Point;
