import React from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import MailItem from './MailItem';
import { useMail } from '@app/modules/Mail/MailContext';
import { Paginator } from 'primereact/paginator';

const MailList = () => {
  const mailCTX = useMail();

  const onPageChange = (event: any) => {
    mailCTX.setMailFilters({
      ...mailCTX.mailFilters,
      Page: event.first,
    });
    mailCTX.setSelectedMail(null);
  };

  return (
    <div
      style={{
        height: 'calc(100%)',
        width: '400px',
        backgroundColor: 'white',
        borderBottom: '2px solid #f0f0f0',
        borderRight: '2px solid #f0f0f0',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#f6f6f6',
          padding: '10px',
          fontWeight: 'bold',
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        {mailCTX.currentBox === '0' && 'Messages du jour'}
        {mailCTX.currentBox === '1' && mailCTX.inboxMessagesUnreadCount + ' message(s) non lu(s)'}
        {mailCTX.currentBox === '2' && 'Messages envoyés'}
        {mailCTX.currentBox === '1-1' && 'Messages reçus supprimés'}
        {mailCTX.currentBox === '2-1' && 'Messages envoyés supprimés'}
      </div>
      <div style={{ height: 'calc(100% - 105px)' }}>
        {mailCTX.currentBox === '0' &&
          mailCTX.dailyMessages &&
          mailCTX.dailyMessages
            .sort((a, b) => new Date(b.ValidityFrom).getTime() - new Date(a.ValidityFrom).getTime())
            .map((mail) => <MailItem mail={mail}></MailItem>)}
        {mailCTX.currentBox === '1' &&
          mailCTX.inboxMessages &&
          mailCTX.inboxMessages
            .sort((a, b) => new Date(b.DateCreation ?? '').getTime() - new Date(a.DateCreation ?? '').getTime())
            .map((mail) => <MailItem mail={mail}></MailItem>)}
        {mailCTX.currentBox === '2' &&
          mailCTX.outboxMessages &&
          mailCTX.outboxMessages
            .sort((a, b) => new Date(b.DateCreation ?? '').getTime() - new Date(a.DateCreation ?? '').getTime())
            .map((mail) => <MailItem mail={mail}></MailItem>)}
        {mailCTX.currentBox === '1-1' &&
          mailCTX.trashInboxMessages &&
          mailCTX.trashInboxMessages
            .sort((a, b) => new Date(b.DateCreation ?? '').getTime() - new Date(a.DateCreation ?? '').getTime())
            .map((mail) => <MailItem mail={mail}></MailItem>)}
        {mailCTX.currentBox === '2-1' &&
          mailCTX.trashOutboxMessages &&
          mailCTX.trashOutboxMessages
            .sort((a, b) => new Date(b.DateCreation ?? '').getTime() - new Date(a.DateCreation ?? '').getTime())
            .map((mail) => <MailItem mail={mail}></MailItem>)}
      </div>
      <Paginator
        style={{ position: 'relative', bottom: '0', width: '100%', borderTop: '2px solid #f0f0f0' }}
        first={mailCTX?.mailFilters?.Page}
        rows={mailCTX?.mailFilters?.Pagesize}
        totalRecords={
          mailCTX.currentBox === '1'
            ? (mailCTX.inboxMessagesCount as number)
            : mailCTX.currentBox === '1-1'
              ? (mailCTX.trashInboxMessagesCount as number)
              : mailCTX.currentBox === '2'
                ? (mailCTX.outboxMessagesCount as number)
                : mailCTX.currentBox === '2-1'
                  ? (mailCTX.trashOutboxMessagesCount as number)
                  : mailCTX.currentBox === '0'
                    ? (mailCTX.dailyMessagesCount as number)
                    : 0
        }
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default MailList;
